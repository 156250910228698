import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BehaviorSubject, Observable, Subject, forkJoin, map } from 'rxjs';
import { CountrySchema, HttpResponse, InterestSchema, ProfileCountSchema, ProfileDetailSchema, ProfileSchema } from '../interfaces';
import { API_ROUTES } from '../routes';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MainService } from './main.service';
import { DatePipe } from '@angular/common';
import { CampaignSchema } from 'src/app/feature/modules/post-auth-pages/modules/manage-activity/models/activity.interface';
import { BadgeListSchema, PointSchema, RankSchema, TotalPointSchema } from 'src/app/feature/modules/post-auth-pages/modules/manage-profile/interface/leaderboard.interface';
import { Lightbox } from 'ngx-lightbox';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  @Output() userGuideClickedEvent = new EventEmitter<string>();
  @Output() pointLikeClickedEvent = new EventEmitter<string>();
  
  public datePipe: DatePipe = new DatePipe('en-auto');
  public profileData$: BehaviorSubject<ProfileSchema | string> = new BehaviorSubject<ProfileSchema | string>('');
  public loadImage: Subject<any> = new Subject();
  constructor(private sanitize: DomSanitizer, private http: HttpClient, private mainService: MainService, private _lightbox: Lightbox) { }

  imageLoaded() { this.loadImage.next(true); }


  public get countryList(): Observable<HttpResponse<CountrySchema[]>> {
    return this.http.get<HttpResponse<CountrySchema[]>>(API_ROUTES.COMMON.COUNTRY);
  }

  public get interestList(): Observable<HttpResponse<InterestSchema[]>> {
    return this.http.get<HttpResponse<InterestSchema[]>>(API_ROUTES.COMMON.INTEREST);
  }

  public getInterestListById(intrestIdArray: any): Observable<HttpResponse<InterestSchema[]>> {
    let result = ''; intrestIdArray.forEach((ele: { interest_id: string }, index: number) => {
      result += intrestIdArray.length - 1 === index ? ele.interest_id : ele.interest_id + ','
    });
    const url = API_ROUTES.COMMON.INTEREST + '?ids=' + result;
    return this.http.get<HttpResponse<InterestSchema[]>>(url);
  }

  public profileDetail(): Observable<ProfileDetailSchema> {
    return forkJoin([this.http.get<HttpResponse<ProfileSchema>>(API_ROUTES.COMMON.PROFILE)]).pipe(
      map((res) => {
        const response: ProfileDetailSchema = { detail: res[0].data };
        this.mainService.setUserDetail = response;
        this.profileData$.next(res[0].data);
        return response;
      }),
    );
  }

  public getProfileCount(userId: string): Observable<any> {
    return this.http.get<HttpResponse<ProfileCountSchema>>(API_ROUTES.COMMON.COUNT + '/' + userId).pipe(map((res) => {
      return res;
    }));
  }

  public httpUpdateProfile(data: any, courseList: any[], interestList: any[], userId: string, profileId: string) {
    const body = data;
    delete body.course;
    delete body.interest;
    return forkJoin([this.http.patch<HttpResponse<{ code: string }>>(`${API_ROUTES.COMMON.UPDATEPROFILE}/${userId}`, body),
    this.http.put<HttpResponse<{ code: string }>>(
      `${API_ROUTES.COMMON.UPDATEINTEREST}/${profileId}`, { userInterests: interestList && interestList.length ? interestList.map((x) => x.id) : [] },
    ),
    this.http.post<HttpResponse<{ code: string }>>(`${API_ROUTES.COMMON.UPDATECOURSE}/${userId}`, { type: courseList && courseList.length ? courseList : [] }),
    ]);
  }

  public httpChangePassword(data: any, id: any): Observable<HttpResponse<{ code: string }>> {
    return this.http.patch<HttpResponse<{ code: string }>>(`${API_ROUTES.COMMON.UPDATEPROFILE}/${id}`, data);
  }

  public httpDeleteProfile(): Observable<HttpResponse<{ code: string }>> {
    return this.http.delete<HttpResponse<{ code: string }>>(API_ROUTES.COMMON.DELETE_PROFILE);
  }

  public httpGetReportList(): Observable<HttpResponse<any>> { return this.http.get<HttpResponse<any>>(API_ROUTES.COMMON.REPORTS); }

  public formatDate(date: string | Date) { return this.datePipe.transform(new Date(date), 'yyyy-MM-dd'); }

  public httpPostReport(data: string, id: string): Observable<HttpResponse<{ code: string }>> {
    return this.http.post<HttpResponse<{ code: string }>>(`${API_ROUTES.COMMON.POSTREPORT}/${id}`, data);
  }

  public safeHTML(html: string): SafeHtml { return this.sanitize.bypassSecurityTrustHtml(html); }

  public fetchAllCampaigns(): Observable<HttpResponse<{ data: CampaignSchema[]; count: 0 }>> {
    return this.http.get<HttpResponse<{ data: CampaignSchema[]; count: 0 }>>(API_ROUTES.CAMPAIGNS.GET);
  }

  public fetchAllCampaignsForProject(sortBy: string, orderBy: string, user_id?: string): Observable<HttpResponse<{ data: CampaignSchema[]; count: 0 }>> {
    const url = `?sortBy=${sortBy}&orderBy=${orderBy}&user_id=${user_id}`;
    return this.http.get<HttpResponse<{ data: CampaignSchema[]; count: 0 }>>(`${API_ROUTES.CAMPAIGNS.GETALL}${url}`);
  }

  public zoomImage(_album: any, i: number) { return this._lightbox.open(_album, i); }

  public httpGetNotificationList(limit?: number, page?: number, orderBy?: string): Observable<HttpResponse<any>> {
    const url = `limit=${limit}&page=${page}&order_by=${orderBy}`;
    return this.http.get<HttpResponse<any>>(`${API_ROUTES.HEADER.BELL}?${url}`);
  }

  public httpUpdateNotifyStatus(notify_id: string): Observable<HttpResponse<{ code: string }>> {
    const url = `${API_ROUTES.HEADER.READ}/${notify_id}`;
    return this.http.patch<HttpResponse<{ code: string }>>(url, '');
  }

  public deleteNotify(id: string): Observable<HttpResponse<{ code: string }>> {
    return this.http.delete<HttpResponse<{ code: string }>>(`${API_ROUTES.HEADER.DETAIL}/${id}`);
  }

  // public getFollowFollowingUsers(limit?: number, page?: number, search?: string): Observable<any> {
  //   let sortBy='name'
  //   let orderBy='ASC'
  //   let userType='user'
  //     const userSearchText = search ? `&search=${search}` : '';
  //   const url = `sortBy=${sortBy}&orderBy=${orderBy}&page=${page}&limit=${limit}${userSearchText}&searchType=${userType}`
  //   return this.http.get<HttpResponse<ProfileCountSchema>>(`${API_ROUTES.FOLLOWFOLLOWING}?${url}`);
  // }
  public getFollowFollowingUsers(
    limit?: number,
    page?: number,
    search?: string,
    sortBy: string = 'name',
    orderBy: string = 'ASC',
    searchType: string = 'user',
    accounts: string = 'true',
    roleId: string = '9dedb1e5-eafc-49b1-bc11-fdd6ccc74dfa',
  ): Observable<HttpResponse<{ data: any[]; count: number }>> {
    let params = new HttpParams();
    if (search && searchType) {
      params = params.append('search', search || '');
      params = params.append('searchType', searchType || '');
    }
    if (page) {
      params = params.append('page', page || '');
    }
    if (limit) {
      params = params.append('limit', limit || '');
    }
    if (sortBy) {
      params = params.append('sortBy', sortBy || '');
    }
    if (orderBy) {
      params = params.append('orderBy', orderBy || '');
    }
    if (accounts) {
      params = params.append('is_active', accounts || '');
    }
    if (roleId) {
      params = params.append('role_id', roleId || '');
    }
    return this.http.get<HttpResponse<any>>(`${API_ROUTES.FOLLOWFOLLOWING}`,
    { params: params },);
  }

  public httpGetFooterData(): Observable<HttpResponse<{ code: string }>> {
    return this.http.get<HttpResponse<{ code: string }>>(API_ROUTES.FOOTER_PAGE);
  }

  public patchUpdateUserUUI(id: string, requestPayload: any): Observable<HttpResponse<{ code: string }>> {
    return this.http.patch<HttpResponse<{ code: string }>>(`${API_ROUTES.COMMON.UPDATEPROFILE}/${id}`, requestPayload);
  }
  
  guideClicked(flag: string) { this.userGuideClickedEvent.emit(flag); }

  public httpGetPoints(): Observable<HttpResponse<PointSchema>> {
    return this.http.get<HttpResponse<PointSchema>>(`${API_ROUTES.HEADER.POINTS}`);
  }

  public httpUpdatePointStatus(point_id: string): Observable<HttpResponse<{ code: string }>> {
    const url = `${API_ROUTES.HEADER.POINT_READ}/${point_id}`;
    return this.http.patch<HttpResponse<{ code: string }>>(url, '');
  }
  public httpUpdateBadgeStatus(point_id: string): Observable<HttpResponse<{ code: string }>> {
    const url = `${API_ROUTES.HEADER.BADGE_READ}/${point_id}`;
    return this.http.patch<HttpResponse<{ code: string }>>(url, '');
  }

  public httpGetBadgeList(): Observable<HttpResponse<Array<BadgeListSchema>>> {
    return this.http.get<HttpResponse<Array<BadgeListSchema>>>(API_ROUTES.LEADER_BOARD.BADGE_LIST);
  }

  public httpGetTotalPoint(): Observable<HttpResponse<TotalPointSchema>> {
    return this.http.get<HttpResponse<TotalPointSchema>>(API_ROUTES.LEADER_BOARD.POINT);
  }

  public httpGetUpdatedBadge(): Observable<HttpResponse<{ code: string }>> {
    return this.http.get<HttpResponse<{ code: string }>>(`${API_ROUTES.HEADER.BADGE_UPDATE}`);
  }

  public httpGetRank(): Observable<HttpResponse<RankSchema>> {
    return this.http.get<HttpResponse<RankSchema>>(`${API_ROUTES.HEADER.RANK}`);
  }

  public httpGetFooterActivtyPoints(body: {content_id: string}): Observable<HttpResponse<{ code: string }>> {
    return this.http.post<HttpResponse<{ code: string }>>(`${API_ROUTES.LEADER_BOARD.FOOTER_POINTS}`,body);
  }

  public likeClicked(flag: string) { this.pointLikeClickedEvent.emit(flag); }

  public httpUpdatePointModalViewStatus(profile_id: string): Observable<HttpResponse<{ code: string }>> {
    const url = `${API_ROUTES.PROFILE.TOGGLE_POINT_MODAL}/${profile_id}/points-popUp-status`;
    return this.http.patch<HttpResponse<{ code: string }>>(url, '');
  }

}
