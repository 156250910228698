<ng-container *ngIf="!showOtpScreen">
  <div class="p-4">
    <div class="modal-header justify-content-center border-0 p-0">
      <div class="form-title text-center">
        <h4 class="my-3">{{'consent.AgeVerification' | translate}}</h4>
        <p class="fs-16 text-grey-custom">
          {{'consent.Youarebelow18years' | translate}}
        </p>
      </div>
      <button type="button" class="btn-close" (click)="close()"></button>
    </div>
    <div class="mb-3">
      <form [formGroup]="emailForm" (ngSubmit)="submitEmailForm()">
        <div class="form-group mb-4">
          <label for="email" class="form-label mb-1 text-grey-2 fs-14 fw-600">{{'consent.ParentEmail' | translate}} <span
          class="text-danger">*</span></label>
          <div class="group">
            <input type="text" class="form-control" placeholder="{{'consent.EnterParentEmail' | translate}}" aria-label="Username"
              aria-describedby="basic-addon1" formControlName="email"
              [ngClass]="{ 'outline-red': f['email'].invalid && f['email'].touched }" />
          </div>
          <app-validation-error [control]="f['email']" [controlName]="('consent.ParentEmail' | translate)">
          </app-validation-error>
        </div>
        <button type="submit" class="btn btn-primary rounded-pill mx-auto d-block px-5 "> {{'consent.submit' | translate}}  </button>
      </form>
    </div>
  </div>
</ng-container>


<ng-container *ngIf="showOtpScreen">
  <div class="p-4">
    <div class="modal-header justify-content-center border-0 p-0">
      <div class="form-title text-center">
        <h4 class="my-3">{{'consent.EnterOTP' | translate}}</h4>
        <p class="fs-16 text-grey-custom">
          {{'consent.AnOTPhasbeensent' | translate}}
        </p>
      </div>
      <button type="button" class="btn-close" (click)="close()"></button>
    </div>
    <div class="mb-3">
      <div class="form-title text-center">
        {{ emailForm?.value?.email }} <img width="16px" (click)="editEmail()" class="img-fluid ms-2 mb-1 cursor-pointer" src="assets/images/edit.png" alt="edit">
      </div>
      <form [formGroup]="otpForm" (ngSubmit)="submitOtpForm()">
        <div class="form-group mb-4">
          <label for="email" class="form-label mb-1 text-grey-2 fs-14 fw-600">{{'consent.OTP' | translate}} <span
          class="text-danger">*</span></label>
          <div class="group">
            <input type="number" maxlength="4" min="0" class="form-control" placeholder="{{'consent.EnterOTP' | translate}}"
              (keypress)="onlyNumberKey($event)" formControlName="otp"
              [ngClass]="{ 'outline-red': f2['otp'].invalid && f2['otp'].touched }" />
          </div>
          <app-validation-error [control]="f2['otp']" [controlName]="('consent.OTP' | translate)">
          </app-validation-error>
        </div>
        <a (click)="submitEmailForm()" class="fw-600 d-flex justify-content-center fs-16 resend">{{'consent.RESENDOTP' | translate}} </a>
        <button type="submit" class="btn btn-primary rounded-pill mx-auto d-block px-5 mt-4"> {{'consent.submit' | translate}}  </button>
      </form>
    </div>
  </div>
</ng-container>