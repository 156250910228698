
<div class="modal-content border-0 win-pop">
    <div class="modal-header border-0 p-2">
        <button type="button" (click)="closemodal()" class="btn-close"></button>
      </div>

    <!-- when got point data -->
    <div *ngIf="!badgeId" class="modal-body border-0 py-3 px-4">
        <div class="image">
            <!-- <img  src="assets/images/win.png" alt=""> -->
                <div class="text-center">
                    <div class="banner">    
                    <span class="text">{{'common.youWin' | translate}}</span>
                    </div>
                </div>
        </div>
        <div class="bg-light p-4 w-75 mx-auto mb-5 rounded-pill text-center">
            <p class="text-center mb-1 point">{{points}} {{'myProfile.points' | translate}}</p>
            <p class="text-center mb-3 activity">{{'common.For' | translate}} {{heading}}</p>
            <a (click)="goToLeaderBoard()" class="mb-0 total-points">{{'common.Yougaintotal' | translate}} {{total_point ? total_point : 0}} {{'myProfile.points' | translate}}</a>
        </div>
    </div>

    <!-- when got badge data -->
    <div *ngIf="badgeId" class="modal-body border-0 py-3 px-4">
        <div class="badge_image text-center">
            <img [src]="badge_iamge ? imageUrl + badge_iamge : ''" alt="">
        </div>
        <div class="bg-light p-4 w-75 mx-auto mb-5 rounded-pill text-center">
            <p class="text-center point">{{badge_name}}</p>
            <p class="text-center activity">{{'myProfile.Youearnedanewbadge' | translate}}</p>
           
            <a (click)="goToLeaderBoard()" class="mb-0 total-points">{{'common.Yougaintotal' | translate}} {{total_point ? total_point : 0}} {{'myProfile.points' | translate}}</a>
        </div>
    </div>
</div>