import { ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CommonModule } from '@angular/common';
import { CompressImageService } from '../../services/compress-image.service';
import { CommonService } from '../../services';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-file-upload',
  standalone: true,
  imports: [CommonModule, ImageCropperModule, TranslateModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  title: string = 'fileUpload.profilePicture';
  maintainAspectRatio: boolean = false;
  isImageLoaded: boolean = false;
  imageFile: any;
  fileName: any;
  imageFormat: string = '';
  originalImageFile!: any;
  fileToSend: any;
  progressInterval: any;
  loadedPercentage: any = 0;
  aspectRatio: number = 1 / 1;

  constructor(private activeModal: NgbActiveModal, private _compressImage: CompressImageService, private cd: ChangeDetectorRef,
    private commonService: CommonService) { this.isImageLoaded = true; }

  public close() { this.activeModal.close(); }

  patchData(event: any): void {
    if (event.flag == 'PROFILE') { this.maintainAspectRatio = true; this.aspectRatio = event.aspectRatio ? event.aspectRatio : 1 / 1 }
  }

  async fileChangeEvent(event: any) {
    const file = event.target.files[0];
    this.isImageLoaded = false;
    this.commonService.loadImage.subscribe(() => { this.isImageLoaded = true; })
    if (file) {
      this.imageFile = null;
      const originalImageSize2 = (file.size / (1024 * 1024)) > 1;
      if (originalImageSize2) {
        this._compressImage.compressFile2(file).then((file) => { this.imageFile = file; })
      } else { this.imageFile = file; }
      this.originalImageFile = file;
      this.fileName = file?.name;
      this.imageFormat = file?.type;
      const mb = Math.round(file?.size / 1024);
      if (mb > 51200) {
        var input = document.getElementsByTagName('input')[0];
        input.value = '';
        return;
      }
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.originalImageFile = event.base64;
    const imageBlob = this.dataURItoBlob(event.base64 || '');
    const imageFile = new File([imageBlob], this.fileName, { type: this.imageFormat || 'image/png' });
    this.cd.detectChanges();
    if (imageFile.size > 0.25 * (1024 * 1024)) {
      this._compressImage.compress(imageFile, this.originalImageFile).pipe().subscribe((compressedImage) => {
        this.fileToSend = compressedImage;
      });
    } else { this.isImageLoaded = true; this.fileToSend = imageFile; }
  }

  dataURItoBlob(dataURI: string) {
    let url = dataURI.toString();
    const byteString = window.atob(url.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) { int8Array[i] = byteString.charCodeAt(i); }
    const blob = new Blob([int8Array], {
      type: this.imageFormat || 'image/png',
    });
    return blob;
  }

  imageLoaded() { this.isImageLoaded = false; }

  cropperReady() { }

  loadImageFailed() { }

  uploadFile() { this.activeModal.close({ previewFile: this.originalImageFile, file: this.fileToSend, fileName: this.fileName }); }

  checkDocName() {
    if( this.fileName) {
      return this.fileName;
    }
    return null
  }
}
