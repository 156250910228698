<div class="px-2">
  <div class="modal-header justify-content-center border-0 pb-0">
    <div class="form-title" id="staticBackdropLabel">
      <h4 class="text-center mt-3 mb-1">{{'login.ForgotPassword' | translate}}</h4>
    </div>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
  </div>
  <div class="px-4 pb-5">
    <form [formGroup]="emailForm" (ngSubmit)="submitForm()" novalidate>
      <div class="form-group mb-3 w-100">
        <label for="email" class="form-label mb-1 text-grey-2 fs-14 w-100 fw-600">{{'login.email' | translate}}</label>
        <div class="group">
          <input type="text" class="form-control" placeholder="{{'login.emailPlaceholder' | translate}}" aria-label="Username"
            aria-describedby="basic-addon1" formControlName="email"
            [ngClass]="{ 'outline-red': f['email'].invalid && f['email'].touched }" />
        </div>
        <app-validation-error [control]="f['email']" [controlName]="('login.emailPlaceholder' | translate)">
        </app-validation-error>
      </div>
      <button type="submit" class="btn btn-primary rounded-pill mx-auto d-block mt-4 px-5 w-100"> {{'common.submit' | translate}} </button>
      <div class="or my-4 position-relative">
        <hr class="opacity-1" />
        <span>{{'login.or' | translate}}</span>
      </div>
      <p class="text-center account-div fs-14 mb-0 mt-3 text-grey-custom">{{'login.Backto' | translate}}
        <a (click)="renderModal('LOGIN')" class="fw-600">{{'login.login' | translate}}</a>
      </p>
    </form>
  </div>
</div>