import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FeatureComponent } from './feature/components/feature/feature.component';
import { DeleteAccountComponent } from './feature/components/delete-account/delete-account.component';
import { PrivacyPolicyMobileComponent } from './feature/components/privacy-policy-mobile/privacy-policy-mobile.component';

const routes: Routes = [
  {
    path: 'delete-account',
    component: DeleteAccountComponent,
    title: 'DeleteAccount'
  },
  {
    path: 'privacyPolicy',
    component: PrivacyPolicyMobileComponent,
    title: 'PrivacyPolicy',
  },
  {
    path: '',
    component: FeatureComponent,
    loadChildren: () =>
      import('./feature/feature.module').then((m) => m.FeatureModule),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '',
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration:"top"})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
