import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import {
  API_ROUTES,
  CommonService,
  HttpResponse,
  InterestSchema,
} from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class ManageProfileService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  public updateFile(
    id: string,
    image: Blob,
  ): Observable<HttpResponse<{ code: string }>> {
    let formData = new FormData();
    formData.append('thumbnail', image);
    return this.http.patch<HttpResponse<{ code: string }>>(
      `${API_ROUTES.PROFILE.UPDATEPROFILE}/${id}`,
      formData,
    );
  }

  public modifiedResponseArray(
    interests: Array<{ interest_id: string }>,
    interestList: Array<InterestSchema>,
  ) {
    if (interestList.length && interests && interests.length) {
      const interestArray = interests.map((T) => {
        const filteredInterest = interestList.find(
          (val) => val.id === T.interest_id,
        );
        return filteredInterest;
      }) as Array<InterestSchema>;
      return interestArray && interestArray.length ? interestArray : [];
    } else {
      return [];
    }
  }

  public updateUserProfile(requests: Array<Observable<any>>) {
    return forkJoin(requests);
  }

  public patchUpdateUserLanguage(requestPayload: any): Observable<HttpResponse<any>> {
    return this.http.patch<HttpResponse<any>>(`${API_ROUTES.COMMON.UPDATEPROFILE_LANGUAGE}`, requestPayload);
  }
}
