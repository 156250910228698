<div
  (click)="showDetail(data.id)"
  class="cursor-pointer card h-100 radius-15 border-0 text-start transition-all position-relative top-0 translate-10 overflow-hidden">

  <div class="user-info row px-4 my-2 project-profile d-none">
    <div class="col-md-8 user-info_authername text-grey-2 fs-14 fw-700 cursor-pointer d-flex">
      <img *ngIf="
        ProfileData &&
        ProfileData.profile &&
        ProfileData.profile.profile_url
      " [src]="imageUrl + ProfileData.profile.profile_url" class="me-2 rounded-2 border-grey" />
      {{
      ProfileData && ProfileData.profile && ProfileData.profile.name
      ? getFirstName(ProfileData.profile.name | titlecase)
      : ''
      }}
    </div>

  </div>

  <div class="card-banner cursor-pointer" (click)="showDetail(data.id)">
    <img [src]="imageUrl + data.thumbnail_url" class="img-fluid" referrerpolicy="no-referrer" loading="lazy" />
    <div *ngIf="countryName"
      class="time text-grey-2 fs-12 text-end bg-white rounded-pill position-absolute right-15 top-15 px-2 shadow-lg-custom">
      <span appCountryFlag [countryId]="countryId"></span>
      {{countryName}}
    </div>
  </div>

  <h2 class="fw-700 fs-5 mt-2 cursor-pointer card-title_mh px-4 pt-3" (click)="showDetail(data.id)">
    {{ data.name ? (data.name | limitTo) : '' }}
  </h2>
  <div class="tags mt-2 cursor-pointer px-4" (click)="showDetail(data.id)">
    <ng-container *ngFor="let tag of data.hashtags">
      <span class="me-2 mb-2 text-primary" *ngIf="tag.name">{{ tag.name }}</span>
    </ng-container>
  </div>
  <p (click)="showDetail(data.id)" class="text-grey-2 fs-14 px-4 cursor-pointer">{{ extractTextFromHTML(data.objective) | limitTo: 100}}</p>
  <div class="p-4 post-content mt-auto">
    <div class="user-info row mt-4 align-items-center">
      <div class="col-md-8 user-info_authername text-grey-2 fs-14 fw-700 cursor-pointer" >
        <span  (click)="showUserDetail($event)" class="user-placeholder" *ngIf="(!ProfileData || !ProfileData.profile.profile_url) && ProfileData.bgColor"
          [style.background-color]="ProfileData.bgColor ? ProfileData.bgColor : '#ffff'"> <span
            *ngIf="ProfileData && ProfileData.profile?.name">{{mainService.getProfileImage(ProfileData.profile.name)}}</span>
        </span>
        <span (click)="showUserDetail($event)">
        <img *ngIf="ProfileData && ProfileData.profile && ProfileData.profile.profile_url"
          [src]="imageUrl + ProfileData.profile.profile_url" class="me-2 rounded-pill" />
        {{ProfileData && ProfileData.profile && ProfileData.profile.name ? getFirstName(ProfileData.profile.name | titlecase) : '' }}</span>
      </div>
      <div class="col-md-4 time fs-12 mt-md-0 mt-3 text-md-end fw-700" *ngIf="data.created_at && !data.published_date">
        {{ data.created_at | date }}
      </div>
      <div class="col-md-4 time fs-12 mt-md-0 mt-3 text-md-end fw-700" *ngIf="data.published_date">
        {{ data.published_date | date }}
      </div>
    </div>
    <hr class="opacity-1" />
    <div class="action-option bg-transparent">
      <ul class="d-flex col-12">
        <li (click)="postLikeDislike(data,true, $event)" class="like me-2" [ngClass]="{ active: data.is_liked === true}">
          <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" viewBox="0 0 511.999 511.999" style="enable-background: new 0 0 511.999 511.999"
            xml:space="preserve" width="14">
            <g>
              <g>
                <path
                  d="M15.34,224.948c-8.365,0-15.146,6.782-15.146,15.146v222.458c0,8.365,6.782,15.146,15.146,15.146h91.253V224.948H15.34z" />
              </g>
            </g>
            <g>
              <g>
                <path d="M511.805,308.587c0-19.227-10.331-36.087-25.733-45.321c5.901-8.521,9.362-18.858,9.362-29.985
    c0-29.113-23.686-52.799-52.799-52.799H331.177c3.416-15.48,8.088-38.709,11.341-63.026c8.466-63.281,2.68-98.376-18.207-110.445
    c-13.022-7.523-27.062-9.049-39.534-4.301c-9.635,3.67-22.647,12.693-30.062,34.838l-29.294,76.701
    c-14.851,36.677-60.33,75.182-88.535,96.473V484.4c52.274,18.316,106.822,27.6,162.472,27.6h121.446
    c29.113,0,52.799-23.686,52.799-52.799c0-10.195-2.905-19.725-7.93-27.804c17.601-8.572,29.759-26.646,29.759-47.503
    c0-11.128-3.461-21.463-9.362-29.985C501.474,344.675,511.805,327.814,511.805,308.587z" />
              </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
          </svg>

          <span class="digit-count fs-14 fw-600 mx-1">{{
            data.likedCount
            }}</span>
        </li>
        <li (click)="postLikeDislike(data,false, $event)" class="dislike me-3" [ngClass]="{ active: data.is_liked === false }">
          <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" viewBox="0 0 511.999 511.999" style="enable-background: new 0 0 511.999 511.999"
            xml:space="preserve" width="14">
            <g>
              <g>
                <path
                  d="M15.34,224.948c-8.365,0-15.146,6.782-15.146,15.146v222.458c0,8.365,6.782,15.146,15.146,15.146h91.253V224.948H15.34z" />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M511.805,308.587c0-19.227-10.331-36.087-25.733-45.321c5.901-8.521,9.362-18.858,9.362-29.985
                                 c0-29.113-23.686-52.799-52.799-52.799H331.177c3.416-15.48,8.088-38.709,11.341-63.026c8.466-63.281,2.68-98.376-18.207-110.445
                                 c-13.022-7.523-27.062-9.049-39.534-4.301c-9.635,3.67-22.647,12.693-30.062,34.838l-29.294,76.701
                                 c-14.851,36.677-60.33,75.182-88.535,96.473V484.4c52.274,18.316,106.822,27.6,162.472,27.6h121.446
                                 c29.113,0,52.799-23.686,52.799-52.799c0-10.195-2.905-19.725-7.93-27.804c17.601-8.572,29.759-26.646,29.759-47.503
                                 c0-11.128-3.461-21.463-9.362-29.985C501.474,344.675,511.805,327.814,511.805,308.587z" />
              </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
          </svg>
          <span class="digit-count fs-14 fw-600 mx-1">{{
            data.dislikedCount
            }}</span>
        </li>
      </ul>
    </div>
  </div>
</div>