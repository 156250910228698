import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AESEncryptDecryptServiceService } from './aesencrypt-decrypt-service.service';
import { ProfileDetailSchema } from '../interfaces';
import { AUTH_KEYS } from 'src/app/core/enums';
import { Observable, from, of, switchMap } from 'rxjs';
import { ProfileUser } from '../models/user-profile';
import { Firestore, collection, collectionData, doc, docData, query, setDoc } from '@angular/fire/firestore';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root',
})
export class MainService {
  constructor(private firestore: Firestore, private cookieService: CookieService, private aes: AESEncryptDecryptServiceService, private translate: TranslateService) { }

  get getUserDetail(): ProfileDetailSchema {
    // return (this.aes.decryptData(this.cookieService.get(AUTH_KEYS.USER_SESSION)) || '');
    return (this.aes.decryptData(localStorage.getItem(AUTH_KEYS.USER_SESSION)) || '');
  }

  set setUserDetail(userDetail: ProfileDetailSchema) {
    const language = userDetail?.detail?.language?.language_code ? (userDetail?.detail?.language?.language_code).toLowerCase() : 'en';
    if(language){
      localStorage.setItem('selectedLang',language)
      this.translate.use(language);
    }
    // this.cookieService.set(AUTH_KEYS.USER_SESSION, this.aes.encryptData(userDetail), { path: '/' });
    localStorage.setItem(AUTH_KEYS.USER_SESSION, this.aes.encryptData(userDetail));
  }

  get currentUserProfile$(): Observable<any> {
    if (this.getUserDetail.detail?.id && localStorage.getItem('fireBaseUserId')) {
      const user_uid: any = localStorage.getItem('fireBaseUserId') ? localStorage.getItem('fireBaseUserId') : '';
      const ref = doc(this.firestore, 'users', user_uid);
      
      return docData(ref) as any;
    } else { return of(null); }
  }

  currentSelectedUserProfile$(userId: string): Observable<ProfileUser | null> {
    const user_uid: any = userId ? userId : '';
    const ref = doc(this.firestore, 'users', user_uid);
    return docData(ref) as Observable<ProfileUser>;
  }

  get allUsers$(): Observable<ProfileUser[]> {
    const ref = collection(this.firestore, 'users');
    const queryAll = query(ref);
    return collectionData(queryAll) as Observable<ProfileUser[]>;
  }

  scrollTo(el: Element): void {
    if (el) { return el.scrollIntoView({ behavior: 'smooth', block: 'center' }); }
    else { return; }
  }

  scrollToError(): void {
    const firstElementWithError: any = document.querySelector('.ng-invalid[formControlName]');
    const firstTagtWithError: any = document.getElementsByTagName('app-validation-error');
    if (firstElementWithError) { return this.scrollTo(firstElementWithError); }
    else if (firstTagtWithError && firstTagtWithError[0]) { return this.scrollTo(firstTagtWithError[0]); }
  }

  // getProfileImage(userName: string) { return userName.split(' ').map(name => name[0]).join('').toUpperCase(); }

  getProfileImage(userName: string) { return userName.trim()[0].toUpperCase(); }

  getCountryName(countryList: any, countryId: string) { return countryList.find((ele: any) => ele.id === countryId)?.name }
  getCountrySymbol(countryList: any, countryId: string) { return countryList.find((ele: any) => ele.id === countryId)?.symbol?.toLowerCase() }

  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) { color += letters[Math.floor(Math.random() * 16)]; }
    return color;
  }
}
