import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  DefaultGlobalConfig,
  IndividualConfig,
  ToastrService,
} from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class CustomMessageService {
  constructor(
    public toastr: ToastrService,
    private translate: TranslateService,
  ) {}
  private individualConfig: Partial<IndividualConfig> = {};

  show(message: string, type: string, title?: string) {
    return this.toastr.show(
      message,
      title,
      this.individualConfig,
      this.getToasterIcon(type),
    );
  }

  showHttpMessages(code: string, type: string, title?: string) {
    if(code) {
      return this.toastr.show(
        code
          ? this.translate.instant(`errorCode.${code}`)
          : this.translate.instant('errorCode.Somethingwentwrong'),
        title,
        this.individualConfig,
        this.getToasterIcon(type),
      );
    }else {
      return null;
    }
  }

  getToasterIcon(type: string) {
    if (!type) {
      return DefaultGlobalConfig.iconClasses.info;
    } else {
      switch (type) {
        case 'error': {
          return DefaultGlobalConfig.iconClasses.error;
        }
        case 'warning': {
          return DefaultGlobalConfig.iconClasses.warning;
        }
        case 'success': {
          return DefaultGlobalConfig.iconClasses.success;
        }
        case 'info': {
          return DefaultGlobalConfig.iconClasses.info;
        }
        default: {
          return DefaultGlobalConfig.iconClasses.info;
        }
      }
    }
  }
}
