
  <div class="p-4">
    <div class="modal-header justify-content-center border-0 p-0">
      <div class="form-title text-center">
        <h4 class="my-3">{{'consent.DateofBirthRequired' | translate}}</h4>
        <p class="fs-16 text-grey-custom">
          {{'consent.WerequireyourDateofBirth' | translate}}
        </p>
      </div>
      <button type="button" class="btn-close" (click)="close()"></button>
    </div>
    <div class="mb-3">
      <form [formGroup]="dobForm" (ngSubmit)="submitDobForm()">
        <div class="form-group mb-4">
          <label for="dob" class="form-label mb-1 text-grey-2 fs-14 fw-600">{{'consent.DateofBirth' | translate}} <span
          class="text-danger">*</span></label>
          <div class="group">
              <div class="group position-relative custom-date-placeholder">
                <input id="dob" type="date" (keydown)="disableKeyboardInput($event)" class="form-control" [max]="maxAgeDate" formControlName="dob"
                [ngClass]="{ 'outline-red': f['dob'].invalid && f['dob'].touched }"  [ngClass]="{
                  'outline-red': f['dob'].invalid && f['dob'].touched,
                  'default-date': !f['dob'].value
                }"/>
                <span *ngIf="!f['dob'].value"
                  class="date-placeholder">
                  dd-mm-yyyy
                </span>
              </div>
          </div>
          <app-validation-error [control]="f['dob']" [controlName]="('consent.DateofBirth' | translate)">
          </app-validation-error>
        </div>
        <button type="submit" class="btn btn-primary rounded-pill mx-auto d-block px-5 "> {{'consent.submit' | translate}} </button>
      </form>
    </div>
  </div>