<div class="dropdown profile-dropdown ms-2">
    <button style="min-width: auto;"
      class="btn btn-outline-primary rounded-pill px-3 py-2 ms-auto fw-600 dropdown-toggle dropdown-toggle-custom"
      type="button" id="languageName" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <span *ngIf="!loading || selectedLanguageImage"><img [src]="selectedLanguageImage ? (imageUrl + selectedLanguageImage) : 'assets/images/united-states-flag-icon.png'" alt=""></span>
    </button>
    <div class="dropdown-menu shadow-lg-custom" aria-labelledby="languageName">
      <a (click)="changeLanguage(lang)" class="dropdown-item text-decoration-none fs-14" 
      *ngFor="let lang of languageDropdown">
        <span class="me-2" ><img [src]="lang?.flag_image ? imageUrl + lang?.flag_image : 'assets/images/united-states-flag-icon.png'" alt=""></span>{{lang.language}}
      </a>
    </div>
</div>