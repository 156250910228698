import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Validator } from 'src/app/core/custom-validators/custom-validations';
import {
  HasLowerCaseLetter,
  HasNumber,
  HasSpecialChar,
  HasUpperCaseLetter,
  MainService,
} from '../..';
import { ValidationErrorComponent } from '../../components/validation-error/validation-error.component';
import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-change-password',
  standalone: true,
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  imports: [CommonModule, ReactiveFormsModule, ValidationErrorComponent, TranslateModule],
})
export class ChangePasswordComponent implements OnDestroy {
  public changePasswordForm: FormGroup;
  public subscription: Subscription = new Subscription();
  constructor(
    private activeModal: NgbActiveModal,
    private fb: FormBuilder,
  ) {
    this.changePasswordForm = this.fb.group({
      old_password: ['', [Validators.required]],
      newPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(15),
          Validator.patternValidator(HasUpperCaseLetter, {
            hasCapitalCase: true,
          }),
          Validator.patternValidator(HasLowerCaseLetter, {
            hasSmallCase: true,
          }),
          Validator.patternValidator(HasSpecialChar, { hasSpecialCharacters: true }),
          Validator.patternValidator(HasNumber, { hasNumber: true }),
        ],
      ],
      confirmPassword: ['', [Validators.required]],
    });
  }

  public get f(): { [key: string]: any } { return this.changePasswordForm?.controls; }
  submitForm() {
    if (this.changePasswordForm.valid) { this.activeModal.close(this.changePasswordForm.value) }
    else { this.changePasswordForm.markAllAsTouched(); }
  }
  closeModal() { this.activeModal.close(); }
  ngOnDestroy(): void { this.subscription.unsubscribe(); }
}
