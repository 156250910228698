<div class="modal-content border-0">
  <div class="modal-header justify-content-center">
    <div class="form-title" id="staticBackdropLabel">
      <h2 class="text-center fs-4 mb-1">{{ (title) | translate }}</h2>
    </div>
    <button type="button" class="btn-close" (click)="close()"></button>
  </div>
  <div class="modal-body py-0 px-4 mt-4">
    <!-- <input class="form-control mb-3" type="file" accept="image/*" (change)="fileChangeEvent($event)" /> -->


    <div class="position-relative mb-3">
      <input
        class="form-control"
        required
        type="file"
        #frontFile
        accept="image/*" 
        (change)="fileChangeEvent($event)"
        hidden
      />
      <div
        class="form-control overflow-hidden cursor-pointer"
        (click)="frontFile.click()"
      >
        <span
          class="w-100 line-overflow-ellipsis d-block"
          *ngIf="checkDocName()"
        >
          {{ checkDocName() }}
        </span>

        <span *ngIf="!checkDocName()"
          class="w-100 line-overflow-ellipsis d-block"
          >{{'common.chooseFile' | translate}}</span
        >
      </div>
      <img
        src="/assets/images/upload.svg"
        style="right: 15px; top: 6px"
        class="input-icon link position-absolute"
        (click)="frontFile.click()"
      />
    </div>

    <p class="text-black-2 fs-12 m-0 text-center">  {{ 'common.Recommendedformat' | translate }} </p>
    <div>
      <div class="preload-image d-flex flex-column align-items-center justify-content-center w-100"
        *ngIf="!isImageLoaded">
        <img src="assets/images/loading-gif.gif" style="height: 28px;margin-top: 15px;">
      </div>
      <image-cropper [imageFile]="imageFile" [maintainAspectRatio]="maintainAspectRatio"
        [containWithinAspectRatio]="true" [aspectRatio]="aspectRatio" [onlyScaleDown]="true" format="png"
        (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
  </div>
  <div class="modal-footer border-0 justify-content-center mb-3">
    <button (click)="uploadFile()" type="submit" class="btn btn-primary rounded-pill me-2">
      {{ 'fileUpload.update' | translate }}
    </button>
    <button (click)="close()" type="submit" class="btn btn-outline-danger rounded-pill">
      {{ 'fileUpload.cancel' | translate }}
    </button>
  </div>
</div>