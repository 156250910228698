<ng-container>
  <div class="abt-page py-4">
    <div class="container">
      <div class="minh-100-custom">
        <div class="row">
          <div class="col-12 col-md-12">
            <div class="text-center mb-3">
              <h2 class="fw-500 fs-1">{{'mobile.PrivacyNotice' | translate}}</h2>
            </div>
            <div
              class="card shadow-lg-custom p-4 border-0 rounded-4 minh-100-custom"
            >
              <div class="row">
                <div class="col-12 col-md-12 mb-3">
                  <h1 class="fs-3 mb-0">{{'mobile.PrivacyNotice' | translate}}</h1>
                </div>
                <div class="col-12 col-md-12">
                  <div>
                    <div>
                      <b
                        style="
                          font-family: Arial;
                          font-size: medium;
                          background-color: transparent;
                          color: var(--body-text);
                          text-align: var(--bs-body-text-align);
                        "
                        >{{'mobile.PrivacyNotice' | translate}}</b
                      ><br />
                    </div>
                    <div>
                      <span
                        style="
                          font-family: Arial;
                          font-size: medium;
                          background-color: transparent;
                          color: var(--body-text);
                          font-weight: var(--bs-body-font-weight);
                          text-align: var(--bs-body-text-align);
                        "
                        ><br
                      /></span>
                    </div>
                    <div>
                      <span
                        style="
                          font-family: Arial;
                          font-size: medium;
                          background-color: transparent;
                          color: var(--body-text);
                          font-weight: var(--bs-body-font-weight);
                          text-align: var(--bs-body-text-align);
                        "
                        >{{'mobile.ThisPrivacyNotice' | translate}}</span
                      ><br />
                    </div>
                    <div><br /></div>
                    <div>
                      <b>{{'mobile.OurPrivacyNotice' | translate}}</b>
                    </div>
                    <div>
                      {{'mobile.PlanLtdiscommitted' | translate}}
                    </div>
                    <div>
                      <span
                        style="
                          font-family: Arial;
                          font-size: medium;
                          background-color: transparent;
                          color: var(--body-text);
                          font-weight: var(--bs-body-font-weight);
                          text-align: var(--bs-body-text-align);
                        "
                        > {{'mobile.Foralldataprotectionqueries' | translate}}</span
                      ><br />
                    </div>
                    <div></div>
                    <div>
                      <div>
                        {{'mobile.ThisPrivacyNoticegoverns' | translate}}
                      </div>
                      <div>
                        <span
                          style="
                            background-color: transparent;
                            color: var(--body-text);
                            font-weight: var(--bs-body-font-weight);
                            text-align: var(--bs-body-text-align);
                          "
                          >{{'mobile.Itisimportantthatyouread' | translate}}</span
                        ><br />
                      </div>
                      <div>
                        <span
                          style="
                            background-color: transparent;
                            color: var(--body-text);
                            font-weight: var(--bs-body-font-weight);
                            text-align: var(--bs-body-text-align);
                          "
                          ><br
                        /></span>
                      </div>
                      <div>
                        <span
                          style="
                            background-color: transparent;
                            text-align: var(--bs-body-text-align);
                          "
                          ><div>
                            <b>{{'mobile.Whatpersonadatawe' | translate}}</b>
                          </div>
                          <div>
                            {{'mobile.Thetypeandamountofinformation' | translate}}
                          </div>
                          <div>
                            •<span style="white-space: pre"> </span> {{'mobile.Rememberyourpreferences' | translate}}
                          </div>
                          <div>
                            •<span style="white-space: pre"> </span> {{'mobile.Trackyourbrowsingactivity' | translate}}
                          </div>
                          <div>
                            •<span style="white-space: pre"> </span> {{'mobile.Improvetheperformance' | translate}}
                          </div>
                          <div>
                            <div>
                              {{'mobile.Weonlycollectthefollowingpersonal' | translate}}
                            </div>
                            <div>
                              •<span style="white-space: pre"> </span>{{'mobile.Yourname' | translate}}
                            </div>
                            <div>
                              •<span style="white-space: pre"> </span> {{'mobile.Youremailaddress' | translate}}
                            </div>
                            <div>
                              •<span style="white-space: pre"> </span> {{'mobile.Yourpassword' | translate}}
                            </div>
                            <div>
                              {{'mobile.ThePlatformmay' | translate}}
                            </div>
                            <div>
                              {{'mobile.Orwhenyouemail' | translate}}
                            </div>
                            <div><br /></div>
                            <div>
                              <div>
                                <b>{{'mobile.Whyweprocess' | translate}}</b>
                              </div>
                              <div>
                               {{'mobile.PlanLtdwillonly' | translate}}&nbsp;
                              </div>
                              <div><br /></div>
                              <div>
                                <b>{{'mobile.Whoyourpersonaldata' | translate}}</b>
                              </div>
                              <div>
                                {{'mobile.Yourpersonaldatawillbe' | translate}}&nbsp;
                              </div>
                              <div><br /></div>
                              <div>
                                <b
                                  >{{'mobile.Optingoutofreceivingmessages' | translate}}</b
                                >
                              </div>
                              <div>
                                {{'mobile.Ifyouhavecreatedanaccount' | translate}}
                              </div>
                            </div>
                            <div><br /></div>
                            <div>
                              <div>
                                <b>{{'mobile.Howyourpersonal' | translate}}</b>
                              </div>
                              <div>
                                {{'mobile.PlanLtdtakesthesecurity' | translate}}
                              </div>
                              <div>
                                {{'mobile.WherePlanLtdengagesthird' | translate}}&nbsp;
                              </div>
                              <div><br /></div>
                              <div><b>{{'mobile.Whatyourrightsare' | translate}}&nbsp;</b></div>
                              <div>
                                {{'mobile.Youhavetherightto' | translate}}&nbsp;&nbsp;
                              </div>
                              <div><br /></div>
                              <div><b>{{'mobile.Complaintresolution' | translate}}</b></div>
                              <div>
                                {{'mobile.PlanLtdwillstriveto' | translate}}
                              </div>
                            </div>
                          </div></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
