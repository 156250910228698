import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, OnChanges, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { HeaderService, MainService } from '../../services';
import { TranslateService } from '@ngx-translate/core';
import { CountryFlagModule } from '../../country-flag.module';
import { EventBusService, TokenStorageService } from 'src/app/core/services';
import { ManageProfileService } from 'src/app/feature/modules/post-auth-pages/modules/manage-profile/service/manage-profile.service';
import { environment } from 'src/environment/environment';
import { EmitEvent } from 'src/app/core/helpers';
import { Events } from 'src/app/core/enums';

@Component({
  selector: 'app-language-dropdown',
  standalone: true,
  imports: [CommonModule, CountryFlagModule],
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss'],
})
export class LanguageDropdownComponent implements OnInit, AfterViewChecked{
  public  imageUrl: string = environment.imageUrl;
  @Output() languageCodeEvent = new EventEmitter<string>();
  public loading: boolean = false;
  private subscription: Subscription = new Subscription();
  public countryList: Array<any> = [];
  public languageDropdown: any = [];
  public selectedLanguageImage: string = '';

  constructor(
    private translate: TranslateService,
    private headerService: HeaderService,
    private tokenStorageService: TokenStorageService,
    public mainService: MainService,
    private manageProfileService: ManageProfileService,
    private cdr: ChangeDetectorRef,
    private eventBusService: EventBusService,
  ) {}

  ngOnInit() {
    this.getLanguages();
  }

  ngAfterViewChecked(){
    const selctedLanguage = localStorage.getItem('selectedLang');
    if(this.languageDropdown && selctedLanguage){
      this.selectedLanguageImage = this.languageDropdown.find(
        (ele: any) => ele.language_code.toLowerCase() == selctedLanguage,
      )?.flag_image;
      this.cdr.detectChanges();
    }
  }

  public getLanguages() {
    this.subscription.add(
      this.headerService.getLanguageList().subscribe({
        next: ({ data }) => {
          this.languageDropdown = data;
          this.sendLanguageCode(data[0]?.language_code);
        },
      }),
    );
  }

  changeLanguage(lang: any) {
    const languageCode = lang.language_code.toLowerCase();
    this.loading = true;
    this.translate.use(languageCode);
    localStorage.setItem('selectedLang', languageCode);
    this.eventBusService.emit(new EmitEvent(Events.CHANGE_LANG));
    this.sendLanguageCode(languageCode);

    this.selectedLanguageImage = lang.selectedLanguageImage;
    setTimeout(() => {
      this.loading = false;
    }, 0);

    if (this.isLoggedIn) {
      this.onLanguageChange(lang);
    }
    this.cdr.detectChanges();
    // window.location.reload();
  }

  sendLanguageCode(value: string) {
    this.languageCodeEvent.emit(value);
  }

  onLanguageChange(event: any) {
    let payload = {
      user_id: this.mainService.getUserDetail.detail.id,
      language_id: event?.id,
    };
    this.updateUserProfileLanguage(payload, event?.language_code);
  }

  updateUserProfileLanguage(requestBody: any, language_code: string) {
    this.subscription.add(
      this.manageProfileService.patchUpdateUserLanguage(requestBody).subscribe({
        next: ({ data }) => {
          this.translate.use(language_code.toLowerCase());
          localStorage.setItem('selectedLang', language_code.toLowerCase());
        },
      }),
    );
  }

  public get isLoggedIn(): boolean {
    if (
      this.tokenStorageService.getTokenSession &&
      this.mainService.getUserDetail
    ) {
      return true;
    } else {
      return false;
    }
  }
}
