import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
})
export class I18nModule {
  constructor(translate: TranslateService) {
    translate.addLangs(['en', 'th']);
    let browserLang =
      typeof localStorage !== 'undefined'
        ? localStorage.getItem('selectedLang')
        : null;
    if (!browserLang || browserLang.trim() === '') {
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem('selectedLang', 'en');
      }
      browserLang = 'en';
    }
    translate.use(browserLang);
  }
}
