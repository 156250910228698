<ng-container *ngIf="data">
  <section class="py-4 minh-100-custom bg-grey">
    <div class="container">
      <div class="row">
        <span class="back-page cursor-pointer font-roboto mxw-75" title="{{'common.back' | translate}}" (click)="goBack()">
          <svg width="8" height="16" viewBox="0 0 13 26" fill="none" xmlns="http://www.w3.org/2000/svg"
            class="me-1 back-arrow top-m2 position-relative">
            <path
              d="M3.31874 13.0646L12.7973 0.953878C12.9437 0.768164 12.8116 0.493164 12.5723 0.493164H9.81159C9.63659 0.493164 9.46874 0.575307 9.36159 0.711021L0.243736 12.361C0.0858262 12.5622 0 12.8106 0 13.0664C0 13.3222 0.0858262 13.5705 0.243736 13.7717L9.36159 25.4182C9.46874 25.5575 9.63659 25.636 9.81159 25.636H12.5723C12.8116 25.636 12.9437 25.361 12.7973 25.1753L3.31874 13.0646Z"
              fill="#585555" />
          </svg> {{ 'common.back' | translate }}
        </span>
      </div>
      <div class="row">
        <h2 class="fw-700 fs-3 text-left mt-0 mb-2 mt-1">
          {{ pageData.title | translate }}
        </h2>
      </div>
      <div class="card shadow-lg-custom card border-0">
        <div class="row">
          <div class="col-lg-5 detail-left">
            <div class="wrap slider-view mb-2 mx-auto px-4">
                <div class="thumbnails d-flex justify-content-center mt-3">
                  <ngx-slick-carousel class="carousel border-grey radius-15" #slickModal="slick-carousel" [config]="slideConfig">
                    <div ngxSlickItem *ngFor="let slide of slides; let i = index" class="slide">
                      <div class="title-img" (click)="openImageSlider(i)">
                        <img [src]="slide" alt="slide" width="100%" class="img-fluid radius-15" loading="lazy" />
                      </div>
                    </div>
                  </ngx-slick-carousel>
                </div>
            </div>
            <div class="thumbnail-slider-view mb-5 mx-4">
              <div class="d-flex justify-content-center">
                  <div *ngFor="let slide of slides; let i = index" class="thumbnail-slide mx-1 cursor-pointer border-grey radius-10" [ngClass]="{'thumbnail-active' : selectedThumbnailIndex === i}" (click)="handleThumbnailClick(i)">
                    <div class="title-img">
                      <img [src]="slide" alt="slide" width="100%" class="img-fluid radius-10" loading="lazy" />
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="col-lg-7 detail-right">
            <div class="p-4 post-content">
              <div class="user-info cursor-pointer row align-items-center">
                <div *ngIf="ProfileData && ProfileData.profile "
                  class="col-lg-4 user-info_authername text-grey-2 fs-14 fw-700"
                  [routerLink]="selfUser ? ['/post-auth/my-profile'] : ['/post-auth/find-people/detail', ProfileData.id, ProfileData.profile.id]">
                  <span class="user-placeholder"
                    *ngIf="(!ProfileData || !ProfileData.profile.profile_url) && data.user?.bgColor"
                    [style.background-color]="ProfileData.bgColor ? ProfileData.bgColor : '#ffff'">
                    <span *ngIf="ProfileData && ProfileData.profile?.name">
                      {{mainService.getProfileImage(ProfileData.profile.name)}}</span></span>
                  <img *ngIf="ProfileData && ProfileData.profile && ProfileData.profile.profile_url"
                    [src]="imageUrl + ProfileData.profile.profile_url" class="me-2 rounded-pill" loading="lazy" />
                  {{ ProfileData && ProfileData.profile && ProfileData.profile.name ? getFirstName(ProfileData.profile.name | titlecase) : '' }}
                </div>
                <div class="col-lg-8 time mt-lg-0 mt-3 text-lg-end">
                  <span class="follower text-grey-2 fs-14 fw-700 me-3">{{'common.Followers' | translate}}: {{followerCount}}</span>
                  <button (click)="_clickEvent('EDIT_CONTRIBUTE')" class="btn btn-sm btn-outline-secondary rounded-pill px-3 py-1 minw-auto me-2" *ngIf="flag == 'projects' && detail.contribution_id && !selfUser">{{'common.MyParticipations' | translate}}</button>
                  <button (click)="_clickEvent('CONTRIBUTE')" *ngIf="flag == 'projects' && !selfUser && !detail.contribution_id" class="btn btn-sm btn-primary rounded-pill px-3 py-1 minw-auto me-2">
                    <!-- <span *ngIf="flag == 'projects' && detail.contribution_id">Already Participated</span> -->
                    <span *ngIf="flag == 'projects'">{{'common.Participate' | translate}}</span>
                  </button>
                  <button *ngIf="!selfUser" (click)="_clickEvent('FOLLOW')"
                    class="btn btn-sm btn-outline-primary rounded-pill me-2 px-3 py-1 minw-auto">
                    {{ followdata?.is_followed ? ('activityDetailUi.following' | translate) : 'activityDetailUi.follow' | translate }}
                  </button>
                  <!-- <button *ngIf="selfUser" (click)="_clickEvent('EDIT')"
                    class="btn btn-sm btn-outline-secondary rounded-pill px-4 py-1 minw-auto">
                    Edit
                  </button> -->
                </div>
              </div>
              <hr class="opacity-1" />

              <div class="title text-grey-2 fw-700 fs-15 mt-4"> {{ data.name }} </div>
              <div class="fw-700 fs-4 mt-2" [innerHTML]="data.call_to_action"></div>
              <div class="fs-15 text-grey-custom mt-3" [innerHTML]="data.objective"></div>
              <div class="tags mt-3 mb-3">
                <span class="me-2 mb-2 text-primary fs-14" *ngFor="let hashtag of data.hashtags">{{ hashtag &&
                  hashtag.name }}</span>
              </div>
                <div class="tags mt-3 mb-3">
                  <h6 class="mb-1 fw-600">{{'addCampaign.countries' | translate}}</h6>
                  <ng-container *ngFor="let country of data.countries; let i = index;">
                    <span class="me-2 mb-2 fs-14 text-grey-2 country-radius-4 border-grey" *ngIf="i < 5">
                      <p class="mb-0" appCountryFlag [countryId]="country?.country_id" *ngIf="country?.country_id"></p>
                      {{ getCountryName(country?.country_id) }}</span>
                    </ng-container>
                    <p class="cursor-pointer fs-13 mt-2 fw-500 text-decoration-un derline d-inline-block text-dark" (click)="openCountryViewModal(data?.countries)" *ngIf="data?.countries?.length > 5"><strong class="cursor-pointer text-primary fs-14 d-inline-block">{{'common.more' | translate}}</strong></p>
                  </div>
              <hr class="opacity-1" />

              <div *ngIf="data.social_network_links" class="link-wrap-text mb-3 d-flex align-items-center">
                <div class="w-auto me-3">
                  <img src="assets/images/svg/reference_link.svg" alt="">
                </div>
                <div>
                  <h6 class="mb-0 fw-600">{{'activityDetailUi.referenceLink' | translate}}</h6>
                  <a (click)="goToLink(data.social_network_links)"
                  class="fw-400 text-blueshae3 fs-12 Link d-block LinkHover cursor-pointer">
                  {{data.social_network_links}}</a>
                </div>
              </div>
              <div *ngIf="data.video_links && data.video_links.length" class="link-wrap-text mb-2 d-flex  align-items-center">
                <div class="w-auto me-3">
                  <img src="assets/images/svg/video_link.svg" alt="">
                </div>
                <div>
                <h6 class="mb-0 fw-600">{{'activityDetailUi.videoLink' | translate}}</h6>
                <a (click)="goToLink(videoLinks.url)"
                  class="fw-400 text-blueshae3 fs-12 Link d-block LinkHover cursor-pointer"
                  *ngFor="let videoLinks of data.video_links">{{ videoLinks && videoLinks.url }}</a>
                </div>
                <hr class="opacity-1" />
              </div>

              <div class="edit-option row align-items-center justify-content-between mb-3">
                <div class="col-auto text-grey-custom">
                  <i class="fas fa-eye fs-12 me-1"></i><span class="fs-12">{{
                    data.views_count ? data.views_count : 0
                    }}
                    {{'activityDetailUi.visitors' | translate}}</span>
                </div>
                <div *ngIf="data.created_at" class="col-auto fs-12 fw-700">
                  <strong class="fw-600">{{'activityDetailUi.createdDate' | translate}}</strong> {{ data.created_at | date }}
                </div>
              </div>

              <div class="action-option py-2 px-3 rounded-3 mt-auto row mx-0">
               <div class="col-md-6 d-flex">
                <ul class="d-flex">
                  <li [ngClass]="{ active: isLiked }" class="like me-4" (click)="postLikeDislike(true)">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 511.999 511.999"
                      style="enable-background: new 0 0 511.999 511.999" xml:space="preserve" width="16">
                      <g>
                        <g>
                          <path
                            d="M15.34,224.948c-8.365,0-15.146,6.782-15.146,15.146v222.458c0,8.365,6.782,15.146,15.146,15.146h91.253V224.948H15.34z" />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M511.805,308.587c0-19.227-10.331-36.087-25.733-45.321c5.901-8.521,9.362-18.858,9.362-29.985
                          c0-29.113-23.686-52.799-52.799-52.799H331.177c3.416-15.48,8.088-38.709,11.341-63.026c8.466-63.281,2.68-98.376-18.207-110.445
                          c-13.022-7.523-27.062-9.049-39.534-4.301c-9.635,3.67-22.647,12.693-30.062,34.838l-29.294,76.701
                          c-14.851,36.677-60.33,75.182-88.535,96.473V484.4c52.274,18.316,106.822,27.6,162.472,27.6h121.446
                          c29.113,0,52.799-23.686,52.799-52.799c0-10.195-2.905-19.725-7.93-27.804c17.601-8.572,29.759-26.646,29.759-47.503
                          c0-11.128-3.461-21.463-9.362-29.985C501.474,344.675,511.805,327.814,511.805,308.587z" />
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>

                    <span class="digit-count fs-15 fw-600 ms-2">{{
                      flag == 'campaigns'
                      ? detail.campaign_likedCount
                      : flag == 'projects'
                      ? detail.project_likedCount
                      : flag == 'articles'
                      ? detail.article_likedCount
                      : ''
                      }}</span>
                  </li>
                  <li class="dislike me-3" [ngClass]="{ active: isDisliked }" (click)="postLikeDislike(false)">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 511.999 511.999"
                      style="enable-background: new 0 0 511.999 511.999" xml:space="preserve" width="16">
                      <g>
                        <g>
                          <path
                            d="M15.34,224.948c-8.365,0-15.146,6.782-15.146,15.146v222.458c0,8.365,6.782,15.146,15.146,15.146h91.253V224.948H15.34z" />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M511.805,308.587c0-19.227-10.331-36.087-25.733-45.321c5.901-8.521,9.362-18.858,9.362-29.985
                                                 c0-29.113-23.686-52.799-52.799-52.799H331.177c3.416-15.48,8.088-38.709,11.341-63.026c8.466-63.281,2.68-98.376-18.207-110.445
                                                 c-13.022-7.523-27.062-9.049-39.534-4.301c-9.635,3.67-22.647,12.693-30.062,34.838l-29.294,76.701
                                                 c-14.851,36.677-60.33,75.182-88.535,96.473V484.4c52.274,18.316,106.822,27.6,162.472,27.6h121.446
                                                 c29.113,0,52.799-23.686,52.799-52.799c0-10.195-2.905-19.725-7.93-27.804c17.601-8.572,29.759-26.646,29.759-47.503
                                                 c0-11.128-3.461-21.463-9.362-29.985C501.474,344.675,511.805,327.814,511.805,308.587z" />
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                    <span class="digit-count fs-15 fw-600 ms-2">{{flag == 'campaigns' ? detail.campaign_dislikedCount :
                      flag == 'projects' ? detail.project_dislikedCount : flag == 'articles' ?
                      detail.article_dislikedCount : '' }}</span>
                  </li>
                </ul>
                <div tabindex="0" [autoClose]="'outside'" class="share-icon fs-13 fw-600 mt-1 mx-2" placement="top" [ngbPopover]="popoverContent" title="{{'common.share' | translate}}"><img class="me-1" src="assets/images/svg/share.svg" alt="share">{{'common.share' | translate}}</div>
                <!-- popover Template -->
                <ng-template #popoverContent>
                  <p class="fs-6 fw-500 mb-2">{{'common.sharethison' | translate}}</p>
                  <ul class="d-flex">
                    <li
                      class="me-2 list-unstyled"><a href="https://www.facebook.com/sharer.php?t={{detail?.data?.name}}&u={{socialShareUrl}}" target="_blank"><img src="assets/images/facebook.svg" class="width-26"
                          alt="facebook"></a></li>
                    <li class="me-2 list-unstyled">
                      <a href="https://twitter.com/intent/tweet?url={{socialShareUrl}}&text={{detail?.data?.name}}" target="_blank"><img src="assets/images/twitter.svg" class="width-26" alt="twitter"></a>
                    </li>
                    <li class="list-unstyled">
                      <a href="https://www.linkedin.com/sharing/share-offsite/?url={{socialShareUrl}}" target="_blank"><img src="assets/images/linkedin.svg" class="width-26" alt="linkedin"></a>
                    </li>
                  </ul>
                </ng-template>
                <span *ngIf="!selfUser" class="outer-report fs-13 fw-600 mt-1 mx-2" (click)="openModal()">
                  <strong 
                    class="me-2 position-relative"><img src="assets/images/svg/report.svg" alt=""
                      class="width-13 position-relative z-index-1"></strong>{{'common.report' | translate}}</span>
                  </div>
                <div
                  class="dropdown dropdown-with-icon col-md-6 text-end more-option d-flex justify-content-md-end justify-content-between mt-md-0 mt-3 px-0">
                  <div *ngIf="data?.facebook_link || data?.twitter_link || data?.linkedin_link"
                    class="share d-flex justify-content-end align-items-center">
                    <ul class="d-flex">
                      <li *ngIf="data?.facebook_link" (click)="goToLink(data?.facebook_link)"
                        class="me-2 list-unstyled"><a><img src="assets/images/facebook.svg" class="width-26"
                            alt="facebook"></a></li>
                      <li *ngIf="data?.twitter_link" (click)="goToLink(data?.twitter_link)" class="me-2 list-unstyled">
                        <a><img src="assets/images/twitter.svg" class="width-26" alt="twitter"></a>
                      </li>
                      <li *ngIf="data?.linkedin_link" (click)="goToLink(data?.linkedin_link)" class="list-unstyled">
                        <a><img src="assets/images/linkedin.svg" class="width-26" alt="linkedin"></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>


              <!-- comment section -->
              <div class="comment-row mt-3">
                <div class="d-flex justify-content-center row">
                  <div class="col-12">
                    <p class="comment-counter text-end fs-12 text-grey-2">
                      <!-- <span *ngIf="pendingComments" class="me-3 text-danger"><strong>{{pendingComments}}</strong> Pending Comments</span> -->
                      <span><strong>{{totalComments}}</strong> {{'activityDetailUi.comments' | translate}}</span>
                    </p>
                    <div class="d-flex flex-column">
                      <div class="p-2 parent-comment">
                        <div class="d-flex flex-md-row flex-column align-items-start">
                          <div class="user-info cursor-pointer row align-items-center">
                            <div class="col-lg-6 user-info_authername text-grey-2 fs-14 fw-700">
                              <span class="user-placeholder"
                                *ngIf="isLoggedIn && (!selfProfile || !selfProfile?.profile.profile_url) && selfProfile?.bgColor"
                                [style.background-color]="selfProfile.bgColor ? selfProfile.bgColor : '#ffff'">
                                <span *ngIf="selfProfile && selfProfile.profile?.name">
                                  {{mainService.getProfileImage(selfProfile.profile.name)}}</span></span>
                              <img *ngIf="selfProfile && selfProfile.profile && selfProfile.profile.profile_url"
                                [src]="imageUrl + selfProfile.profile.profile_url" class="me-2 chat-user rounded-pill"
                                loading="lazy" />
                            </div>
                          </div>
                          <div class="position-relative col-parent w-100 ms-md-2">
                            <div class="meesage-box position-relative w-100">
                              <textarea [formControl]="commentCtrl" (keydown.enter)="postComment($event)" id="meesageArea" #meesageArea name="meesageArea"
                                class="form-control shadow-none textarea" placeholder="{{'activityDetailUi.addComment' | translate}}"></textarea>
                              <div class="action-opt right-15 position-absolute top-10">
                                <label for="commentImage" class="file-label">
                                  <i class="fas fa-camera cursor-pointer me-3 text-grey-2"></i>
                                </label>
                                <input type="file" #commentImage
                                  accept="image/*,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                                  (change)="onMediaFileSelected($event)" class="file-upload d-none" id="commentImage">
                                <span #container name="container" id="container">
                                  <i class="fa-regular fa-face-smile cursor-pointer" (click)="toggleEmojiPicker()"></i>
                                  <emoji-mart set="facebook" [include]="orderedCategories" [autoFocus]="true"
                                    [isNative]="false" class="emoji-mart" *ngIf="showEmojiPicker"
                                    (emojiSelect)="addEmoji($event)" title="{{'common.Pickyouremoji' | translate}}"></emoji-mart>
                                </span>
                              </div>
                            </div>

                            <div class="mt-2 text-end"><button (click)="clearTextInput()"
                                class="btn btn-sm btn-outline-primary rounded-pill me-2 px-4 py-1 minw-auto"
                                type="button">{{'activityDetailUi.cancel' | translate}}</button>
                              <button (click)="postComment($event)"
                                class="btn btn-primary rounded-pill minw-auto btn-sm shadow-none px-2 py-1"
                                type="button" title="{{'common.Postcomment' | translate}}"><i
                                  class="fa-solid fa-paper-plane text-white"></i></button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- parent-comment end -->

                      <!-- posted comment -->
                      <div *ngFor="let item of comments; let i = index" class="p-2 parent-comment">
                        <div class="d-flex flex-md-row flex-column align-items-start">
                          <div class="user-info cursor-pointer row align-items-center">
                            <div class="col-lg-6 user-info_authername text-grey-2 fs-14 fw-700">

                              <span class="user-placeholder"
                                *ngIf="(!item?.user || !item?.user?.profile?.profile_url) && item?.bgColor"
                                [style.background-color]="item.bgColor ? item.bgColor : '#ffff'">
                                <span *ngIf="item?.user && item?.user?.profile?.name">
                                  {{mainService.getProfileImage(item?.user?.profile.name)}}</span></span>
                              <img *ngIf="item?.user && item?.user?.profile && item?.user?.profile?.profile_url"
                                [src]="imageUrl + item?.user?.profile?.profile_url" class="me-2 rounded-pill"
                                loading="lazy" />
                            </div>
                          </div>
                          <div class="position-relative col-parent w-100 ms-md-2">
                            <div class="meesage-box position-relative w-100 d-none">
                              <textarea class="form-control shadow-none textarea" placeholder="Add comment"></textarea>
                              <div class="action-opt right-15 position-absolute top-10">
                                <i class="fas fa-camera cursor-pointer me-3 text-grey-2"></i>
                                <i class="fa-regular fa-face-smile  cursor-pointer text-grey-2"></i>
                              </div>
                            </div>

                            <div class="comment-detail p-3 bg-grey border-grey position-relative rounded-3">
                              <div class="commnet-header d-flex justify-content-between">
                                <h4 class="fs-6 mb-1">{{ getFirstName(item?.user?.profile?.name | titlecase)}}</h4>
                                <span class="date-time fs-11 text-grey-2 fst-italic">{{item?.created_at | date}}</span>
                              </div>
                              <p *ngIf="item?.comment" class="fs-13 mb-0">{{item?.comment | censor: restrictedWords}}</p>
                              <img height="50%" width="50%" *ngIf="item?.comment_images[0]?.image_url" [src]="item?.comment_images[0]?.image_url ? imageUrl + item?.comment_images[0]?.image_url : ''" alt="">
                            </div>

                            <div class="mt-2 text-end d-none"><button (click)="clearTextInput()"
                                class="btn btn-sm btn-outline-primary rounded-pill me-2 px-4 py-1 minw-auto"
                                type="button">{{'activityDetailUi.cancel' | translate}}</button>
                              <button class="btn btn-primary rounded-pill minw-auto btn-sm shadow-none px-2 py-1"
                                type="button" title="{{'common.Postcomment' | translate}}"><i
                                  class="fa-solid fa-paper-plane text-white"></i></button>
                            </div>
                            <div class="d-flex flex-wrap flex-md-nowrap justify-content-md-between mt-2">
                              <div class="like-comment mb-md-0 mb-2 fs-13 col-md-6 col-12">
                                <span class="cursor-pointer me-3">
                                  <span (click)="commentLike(item, item?.user_like)">
                                    <img src="assets/images/like-default.svg" class="default w-auto h-auto me-2"
                                      *ngIf="item.user_like !== true">
                                    <img src="assets/images/like-active.svg" class="like w-auto h-auto me-2"
                                      *ngIf="item.user_like === true">
                                  </span>
                                  <strong class="count fw-500">{{item?.likes}}</strong>
                                </span>
                                <span class="cursor-pointer" (click)="openSubComment(item.id, i)">
                                  <i class="fa-regular fa-message text-grey-2 me-1" title="{{'common.replycomment' | translate}}"></i>
                                  <strong class="count fw-500">{{item?.replies_count}}</strong>
                                </span>

                              </div>
                              <div class="post-option col-md-6 col-12 text-md-end"> 
                                <!-- <span (click)="showTranslation(item?.comment)" class="text-primary text-decoration-underline fs-12 me-3 cursor-pointer">{{'activityDetailUi.showTranslation' | translate}}</span> -->
                                <span *ngIf="item?.replies_count > 0" (click)="openSubComment(item.id, i)" class="fs-12 text-grey-2 cursor-pointer"> {{showSubComment && selectedIndex === i ? ('activityDetailUi.hideAnswers' | translate) : ('activityDetailUi.showAnswers' | translate) }}</span>
                                <span *ngIf="item?.selfComment" (click)="deleteComment(item.project_id, item.id)"
                                  class="fs-12 text-grey-2 cursor-pointer ms-3" title="{{'common.Deletecomment' | translate}}"><i class="fa-regular fa-trash-can"></i></span>
                              </div>
                            </div>

                            <div *ngIf="showSubComment && selectedIndex === i">
                              <div *ngFor="let subItem of subComments; let i = index" class="p-2 parent-comment">
                                <div class="d-flex flex-md-row flex-column align-items-start">

                                  <div class="user-info cursor-pointer row align-items-center">
                                    <div class="col-lg-6 user-info_authername text-grey-2 fs-14 fw-700">
                                      <span class="user-placeholder"
                                        *ngIf="(!subItem?.user || !subItem?.user?.profile?.profile_url) && item?.bgColor"
                                        [style.background-color]="subItem.bgColor ? subItem.bgColor : '#ffff'">
                                        <span *ngIf="subItem?.user && subItem?.user?.profile?.name">
                                          {{mainService.getProfileImage(subItem?.user?.profile.name)}}</span></span>
                                      <img *ngIf="subItem?.user && subItem?.user?.profile && subItem?.user?.profile?.profile_url"
                                        [src]="imageUrl + subItem?.user?.profile?.profile_url" class="me-2 rounded-pill"
                                        loading="lazy" />
                                    </div>
                                  </div>

                                  <div class="position-relative col-parent w-100 ms-md-2">
                                    <div class="meesage-box position-relative w-100 d-none">
                                      <textarea class="form-control shadow-none textarea"
                                        placeholder="Add comment"></textarea>
                                      <div class="action-opt right-15 position-absolute top-10">
                                        <i class="fas fa-camera cursor-pointer me-3 text-grey-2"></i>
                                        <i class="fa-regular fa-face-smile  cursor-pointer text-grey-2"></i>
                                      </div>
                                    </div>

                                    <div class="comment-detail p-3 bg-grey border-grey position-relative rounded-3">
                                      <div class="commnet-header d-flex justify-content-between">
                                        <h4 class="fs-6 mb-1">{{ getFirstName(subItem?.user?.profile?.name | titlecase)}}</h4>
                                        <span class="date-time fs-11 text-grey-2 fst-italic">{{subItem?.created_at |
                                          date}}</span>
                                      </div>
                                      <p *ngIf="subItem?.comment" class="fs-13 mb-0">{{subItem?.comment | censor: restrictedWords}}</p>
                                      <img height="50%" width="50%" *ngIf="subItem?.comment_images[0]?.image_url" [src]="subItem?.comment_images[0]?.image_url ? imageUrl + subItem?.comment_images[0]?.image_url : ''" alt="">
                                    </div>

                                    <div class="mt-2 text-end d-none"><button (click)="clearTextInput()"
                                        class="btn btn-sm btn-outline-primary rounded-pill me-2 px-4 py-1 minw-auto"
                                        type="button">{{'activityDetailUi.cancel' | translate}}</button>
                                      <button
                                        class="btn btn-primary rounded-pill minw-auto btn-sm shadow-none px-2 py-1"
                                        type="button" title="{{'common.Postcomment' | translate}}"><i
                                          class="fa-solid fa-paper-plane text-white"></i></button>
                                    </div>
                                    <div class="d-flex flex-wrap flex-md-nowrap justify-content-md-between mt-2">
                                      <div class="like-comment mb-md-0 mb-2 fs-13 col-md-6 col-12">
                                        <span class="cursor-pointer me-3">
                                          <!-- <i (click)="subCommentLike(item, item.user_like)" [ngClass]="{ 'text-danger': item.user_like === true }" class="fa fa-heart text-grey-2 cursor-pointer me-1" title="like comment"></i> -->
                                          <span (click)="subCommentLike(subItem, subItem?.user_like)">
                                            <img src="assets/images/like-default.svg" class="default w-auto h-auto me-2"
                                              *ngIf="subItem?.user_like !== true">
                                            <img src="assets/images/like-active.svg" class="like w-auto h-auto me-2"
                                              *ngIf="subItem?.user_like === true">
                                          </span>
                                          <strong class="count fw-500">{{subItem?.likes}}</strong>
                                        </span>

                                      </div>
                                      <div class="post-option col-md-6 col-12 text-md-end">
                                        <!-- <span (click)="showReplyTranslation(subItem?.comment)"
                                          class="text-primary text-decoration-underline fs-12 me-3 cursor-pointer">{{'activityDetailUi.showTranslation' | translate}}</span> -->
                                        <span *ngIf="subItem?.selfComment"
                                          (click)="deleteSubComment(subItem.project_id, subItem.parent_id, subItem.id)"
                                          class="fs-12 text-grey-2 cursor-pointer ms-3" title="{{'common.Deletecomment' | translate}}">
                                          <i class="fa-regular fa-trash-can"></i></span>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- child-comment  -->
                            <div *ngIf="showSubComment && selectedIndex === i" class="p-2 child-comment mt-3">
                              <div class="d-flex flex-md-row flex-column align-items-start">
                                <div class="user-info cursor-pointer row align-items-center">
                                  <div class="col-lg-6 user-info_authername text-grey-2 fs-14 fw-700">
                                    <span class="user-placeholder"
                                      *ngIf="isLoggedIn && (!selfProfile || !selfProfile?.profile.profile_url) && selfProfile?.bgColor"
                                      [style.background-color]="selfProfile.bgColor ? selfProfile.bgColor : '#ffff'">
                                      <span *ngIf="selfProfile && selfProfile.profile?.name">
                                        {{mainService.getProfileImage(selfProfile.profile.name)}}</span></span>
                                    <img *ngIf="selfProfile && selfProfile.profile && selfProfile.profile.profile_url"
                                      [src]="imageUrl + selfProfile.profile.profile_url" class="me-2 chat-user rounded-pill" 
                                      loading="lazy" />
                                  </div>
                                </div>
                                <!-- <img class="rounded-pill chat-user mb-md-0 mb-2 border-grey"
                                  src="{{userData && userData.profile && userData.profile.profile_url ? imageUrl + userData.profile.profile_url : ''}}"> -->
                                <div class="position-relative col-parent w-100 ms-md-2">
                                  <div class="meesage-box position-relative w-100">
                                    <textarea [formControl]="subCommentCtrl" (keydown.enter)="postSubComment($event)"  id="replyMessageArea" #replyMessageArea name="replyMessageArea" class="form-control shadow-none textarea"
                                      placeholder="{{'activityDetailUi.addComment' | translate}}"></textarea>
                                    <div class="action-opt right-15 position-absolute top-10">
                                      <label for="commentReplyImage" class="file-label">
                                        <i class="fas fa-camera cursor-pointer me-3 text-grey-2"></i>
                                      </label>
                                      <input type="file" #commentReplyImage
                                        accept="image/*,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                                        (change)="onMediaReplyFileSelected($event, item.id)" class="file-upload d-none" id="commentReplyImage">
                                      <span #replyContainer name="replyContainer" id="replyContainer">
                                        <i class="fa-regular fa-face-smile cursor-pointer" (click)="toggleReplyEmojiPicker()"></i>
                                        <emoji-mart set="facebook" [include]="orderedCategories" [autoFocus]="true"
                                          [isNative]="false" class="emoji-mart" *ngIf="showReplyEmojiPicker"
                                          (emojiSelect)="addReplyEmoji($event)" title="{{'common.Pickyouremoji' | translate}}"></emoji-mart>
                                      </span>
                                    </div>
                                  </div>

                                  <div class="mt-2 text-end"><button (click)="clearTextInput()"
                                      class="btn btn-sm btn-outline-primary rounded-pill me-2 px-4 py-1 minw-auto"
                                      type="button">{{'activityDetailUi.cancel' | translate}}</button>
                                    <button (click)="postSubComment($event)"
                                      class="btn btn-primary rounded-pill minw-auto btn-sm shadow-none px-2 py-1"
                                      type="button" title="{{'common.Postcomment' | translate}}"><i
                                        class="fa-solid fa-paper-plane text-white"></i></button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- child-comment  end -->

                          </div>
                        </div>
                      </div>
                      <!-- posted comment end -->

                    </div>
                  </div>
                </div>
              </div>
              <!-- comment section end-->

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>