import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountComponent implements OnInit{
  constructor(private route: ActivatedRoute,  public translate: TranslateService) {}
  
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const languageCode = params['lang'] ? params['lang'] : 'en';
      if (languageCode) {
        localStorage.setItem('selectedLang',languageCode)
        this.translate.use(languageCode);
      }
    });
  }

}
