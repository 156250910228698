import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { API_ROUTES } from '../routes';
import { HttpResponse } from '../interfaces';

type HeaderModal = 'LOGIN' | 'SIGNUP';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private modalSubject$: Subject<HeaderModal> = new Subject<HeaderModal>();

  constructor(private http: HttpClient) { }

  public set _setRenderModal(flag: HeaderModal) {
    this.modalSubject$.next(flag);
  }

  public get renderModal() {
    return this.modalSubject$.asObservable();
  }

  public getLanguageList(): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${API_ROUTES.HEADER.LANGUAGES}`);
  }
}
