import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { VerificationEmailComponent } from 'src/app/shared/Modals/verification-email/verification-email.component';
import { CustomMessageService, UserAuthService } from 'src/app/shared/services';
import { LoginComponent } from '../login/login.component';
import { Validator } from 'src/app/core/custom-validators/custom-validations';
import { EmailRegex } from 'src/app/shared';

@Component({
  selector: 'app-age-verification',
  templateUrl: './age-verification.component.html',
  styleUrls: ['./age-verification.component.scss']
})
export class AgeVerificationComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public emailForm!: any;
  public otpForm!: any;
  public showOtpScreen: boolean = false;
  public user_id: string = '';

  constructor(private fb: FormBuilder, private activeModal: NgbActiveModal, private userAuthService: UserAuthService, private toasterService: CustomMessageService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.initializeEmailForm();
    this.initializeOtpForm();
  }

  private initializeEmailForm() {
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email,  Validators.pattern(EmailRegex),
        Validators.maxLength(250),
        Validator.noWhiteSpaceValidator,]],
    });
  }

  private initializeOtpForm() {
    this.otpForm = this.fb.group({
      otp: ['', [Validators.required, Validators.pattern(/^\d{4}$/)]],
    });
  }

  patchDataAge(user_id?: any): void {
    this.user_id = user_id
  }
  
  public close() { this.activeModal.close(); }

  public submitEmailForm() {
    if (this.emailForm.valid) {
      let payload = {
        email: this.emailForm?.value?.email,
        user_id: this.user_id
      }
      this.subscription.add(this.userAuthService.ageVerify(payload).subscribe({
        next: ({ data }) => {
          if (data && data.code) { this.toasterService.showHttpMessages(data.code, 'success'); }
          this.showOtpScreen = true;
        },
      }));
    } else { this.emailForm.markAllAsTouched(); }
  }

  public submitOtpForm() {
    if (this.otpForm.valid) {
      let payload = {
        otp: (this.otpForm?.value?.otp).toString(),
        user_id: this.user_id
      }
      this.subscription.add(this.userAuthService.ageVerifyOtp(payload).subscribe({
        next: ({ data }) => {
          if (data && data.code) { this.toasterService.showHttpMessages(data.code, 'success'); }
          this.activeModal.close('OTP_VERIFIED');      
        },
      }));
    } else { this.emailForm.markAllAsTouched(); }
  }


  editEmail(){
    this.showOtpScreen = false;
  }
  
  onlyNumberKey(event: any): boolean {
    const charCode = event.charCode || event.keyCode;
    if (
      charCode < 48 || charCode > 57
    ) {
      return false;
    }
    const input = event.target as HTMLInputElement;
    if (input.value.length >= 4) {
      return false;
    }
    return true;
  }

  public get f(): { [key: string]: AbstractControl } { return this.emailForm.controls; }

  public get f2(): { [key: string]: AbstractControl } { return this.otpForm.controls; }

  ngOnDestroy(): void { this.subscription.unsubscribe(); }
}

