import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { CountryFlagModule } from '../../country-flag.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventBusService } from 'src/app/core/services';
import { Events } from 'src/app/core/enums';

@Component({
  selector: 'app-multi-select-dropdown',
  standalone: true,
  imports: [CommonModule, FormsModule, CountryFlagModule, TranslateModule],
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectDropdownComponent),
      multi: true
    }
  ]
})
export class MultiSelectDropdownComponent {
  private subscription: Subscription = new Subscription();
  list: any = [];
  temp_list: any = [];
  @Input() asyncList!: Observable<any[]>;
  @Output() shareCheckedList = new EventEmitter();
  @Output() shareIndividualCheckedList = new EventEmitter();
  
  @Input("items") set items(value: any) {
    this.list = value;
    this.temp_list = value;
  }

  @Input() checkedList: any[] = [];
  public currentSelected: {} | undefined;
  public showDropDown: boolean = false;
  @Input() selectedList: any = [];
  @Input() placeholder: string = '';
  public allSelected: boolean = false;
  keyword = "";
  filterList: any = [];

  constructor(private translate: TranslateService, private eventBusService: EventBusService) {
    this.subscription.add(
      this.eventBusService.on(Events.CHANGE_LANG, () => {
        const selectedLang = localStorage.getItem('selectedLang') || 'en';
        if (selectedLang) {
          this.translate.use(selectedLang);
          
          // Re-translate the list based on the selected language
          this.updateTranslatedList(selectedLang);
        }
      })
    );
  
    this.allSelected = false;
    this.checkedList = [];
    this.selectedList = [];
    this.currentSelected = [];
    this.filterList = this.list;
    this.placeholder = this.placeholder ? this.placeholder : this.translate.instant('addCampaign.countriesPlaceholder');
  }
  
  updateTranslatedList(lang: string) {
    // Update the list with the translated names for the selected language
    this.list = this.temp_list.map((item: any) => {
      const translatedName = item[`name_${lang}`];
      return { ...item, name: translatedName || item.name }; // Default to item.name if no translation exists
    });
  
    // Update the filterList for searching
    this.filterList = [...this.list];
  
    // After updating the list, update the checked items
    this.updateCheckedItems(lang);
  }
  
  updateCheckedItems(lang: string) {
    // Ensure that the selectedList and checkedList are updated with the translated names
    this.checkedList = [];
    this.selectedList.forEach((id: any) => {
      const itemIndex = this.list.findIndex((ele: any) => ele.id === id);
      if (itemIndex !== -1) {
        const translatedName = this.list[itemIndex].name;
        // Ensure only the translated name for the selected language is pushed
        if (!this.checkedList.includes(translatedName)) {
          this.checkedList.push(translatedName);
        }
      }
    });
  }
  
  getTranslatedName(data: any): any {
    // Retrieve the translated name based on the selected language
    const selectedLang = localStorage.getItem('selectedLang') || 'en';
    const translatedName = data[`name_${selectedLang}`];
    return translatedName || data.name;  // Default to data.name if no translation exists
  }
  
  public getSelectedValue(status: Boolean, value: any, selectAllFlag?: boolean) {
    const translatedName = this.getTranslatedName(value);
  
    if (status) {
      if (
        !this.checkedList.includes(translatedName) &&
        !this.selectedList.includes(value.id)
      ) {
        this.checkedList.push(translatedName);
        this.selectedList.push(value.id);
      }
    } else {
      this.allSelected = false;
      const index = this.checkedList.indexOf(translatedName);
      this.selectedList.splice(index, 1);
      this.checkedList.splice(index, 1);
    }
  
    this.currentSelected = { checked: status, value: translatedName };
    
    this.shareCheckedlist();
    this.shareIndividualStatus();
    this.allSelected = selectAllFlag ? this.allSelected : (this.checkedList.length === this.list.length);
  }
  

  search(e: any) {
    const lang = localStorage.getItem('selectedLang') || 'en';  // Default to 'en' if language is not set
    const fieldName = lang == 'en' ? 'name' : `name_${lang}`; 

    const val = e.toLowerCase().normalize(); // Normalize the input string to handle characters properly
    const temp = this.temp_list.filter((x: any) => {
      const name = x[fieldName]?.toLowerCase().normalize(); // Normalize the name for comparison
      if (name.indexOf(val) !== -1 || !val) {
        return x;
      }
    });
    this.list = temp;
    this.allSelected = (this.checkedList.length === this.list.length);
  }
  

  public selectAllToggle() {
    this.list?.forEach((ele: any) => {
      this.checkedList = this.allSelected ? this.checkedList : [];
      ele.checked = this.allSelected;
      this.getSelectedValue(this.allSelected, ele, true);
    });
    this.allSelected = (this.checkedList.length === this.list.length);
  }

  public shareCheckedlist() {
    this.shareCheckedList.emit(this.selectedList);
  }

  public shareIndividualStatus() {
    this.shareIndividualCheckedList.emit(this.currentSelected);
  }
}
