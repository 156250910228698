<header class="position-fixed top-0 w-100 bg-white z-index-9" [ngClass]="{ active: scrolled }">
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light">
      <button id="sidebarToggle" (click)="toggleField()" class="navbar-toggler" type="button" data-toggle="collapse"
        data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false"
        aria-label="Toggle navigation" [ngClass]="classToggled ? 'open':'close'">
        <span class="navbar-toggler-icon"></span>
        <i class="fas fa-times"></i>
      </button>

      <div class="btn-group pull-right top-head-dropdown me-3 mobile-notification d-xl-none d-block"
        *ngIf="userLoggedIn">
        <button type="button" class="btn btn-default dropdown-toggle no-drop position-relative"
          data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img src="assets/images/notification-icon-2.svg" class="width-20 default" alt="Notification"
          title="{{'common.Notification' | translate}}">
          <img src="assets/images/notification-icon-active.svg" class="width-20 active" alt="Notification"
          title="{{'common.Notification' | translate}}">
          <span *ngIf="notifyCount>0" class="counter position-absolute rounded-pill text-white">{{notifyCount}}</span>
        </button>
        <div class="notification-drop dropdown-menu p-0">
          <div *ngIf="notifyList?.length==0" class="no-nofication text-center my-4 px-3">
            <img src="assets/images/no-notification.png" class="mx-auto my-3">
            <h5 class="fs-18">{{"header.noNotification" | translate}}</h5>
            <p class="fs-13 text-grey-custom fw-500">{{"header.noNotificationSubHeading" | translate}}</p>
          </div>
          <ul class="dropdown-menu-right p-0 mb-0">
            <li *ngFor="let item of notifyList">
              <div class="top-text-block d-flex pe-3" [ngClass]="{'unread':!item.is_read}">
                <div class="wrap w-100 cursor-pointer" (click)="readNotify(item)">
                  <div class="top-text-heading fs-14">{{ getCurrentLanguageName(item) }}</div>
                  <div class="top-text-light">{{item?.created_at | date }}</div>
                </div>
                <div class="cursor-pointer" title="{{'common.DeleteNotification' | translate}}" [ngClass]="{'text-grey':item.is_read}"
                  (click)="deleteNotify(item.id)"><i class="far fa-trash-alt"></i></div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- <div class="d-none"> -->
      <ng-container>
        <div class="d-xl-none d-block mobile-language-dropddown" [ngClass]="{'rightMargin' : !userLoggedIn}">
          <app-language-dropdown (languageCodeEvent)="getLanguageCode($event)"></app-language-dropdown>
        </div>
      </ng-container>
      <!-- </div> -->

      <a class="navbar-brand" routerLink="/"><img src="assets/images/logoyap2.png" alt="" /></a>

      <div class="collapse navbar-collapse" id="navbarTogglerDemo03" [class.show]="classToggled">
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0 ms-auto">
          <!-- <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Toolbox 
            </a>
            <ul class="dropdown-menu overflow-hidden">
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
            </ul>
          </li> -->
          <li class="nav-item active">
            <a id="toolbox-link" class="nav-link" routerLink="/pre-auth/manage-toolbox" routerLinkActive="active">{{"header.Toolbox" | translate}}</a>
          </li>
          <li class="nav-item active">
            <a id="explore-link" class="nav-link" routerLink="/pre-auth/explore/campaigns" routerLinkActive="active">{{"header.Explore" | translate}}</a>
          </li>
          <li class="nav-item">
            <a id="community-link" class="nav-link" routerLink="/pre-auth/community" routerLinkActive="active"> {{"header.community" | translate}}</a>
          </li>
          <li class="nav-item dropdown">
            <a id="news-link" class="nav-link" routerLink="/pre-auth/news" routerLinkActive="active">{{"header.news" | translate}}</a>
          </li>
          <li class="nav-item active">
            <a id="about-link" class="nav-link" routerLink="/about-us" routerLinkActive="active">{{"header.about_us" | translate}}</a>
          </li>
          <!-- <li class="nav-item dropdown" *ngIf="userLoggedIn">
            <a id="news-link" class="nav-link" routerLink="/post-auth/announcements" routerLinkActive="active"><i class="fa fa-bullhorn fs-18"></i></a>
          </li> -->
          <!-- <ng-template #template let-currentStepNumber="num" let-totalSteps="total">
            I'm in {{ num }} of {{ total }}
          </ng-template> -->

          <!-- <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>News</span>
            </a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
            </ul>
          </li> -->
        </ul>
        <div class="ms-auto d-xl-flex align-items-center right-dropdown">
          <ng-container *ngIf="userLoggedIn">
            <!-- Single button -->
            <div class="btn-group pull-right top-head-dropdown me-3 d-xl-block d-none">
              <button type="button" class="btn btn-default dropdown-toggle no-drop position-relative"
                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                
                <img src="assets/images/notification-icon-2.svg" class="width-20 default" alt="Notification"
                title="{{'common.Notification' | translate}}">
                <img *ngIf="!classToggled" src="assets/images/notification-icon-active.svg" class="width-20 active" alt="Notification"
                title="{{'common.Notification' | translate}}">
                <img *ngIf="classToggled" src="assets/images/notification-icon-2.svg" class="width-20 active" alt="Notification"
                title="{{'common.Notification' | translate}}">

                <span *ngIf="notifyCount>0"
                  class="counter position-absolute rounded-pill text-white">{{notifyCount}}</span>
              </button>
              <div class="notification-drop dropdown-menu p-0">
                <div *ngIf="notifyList?.length == 0" class="no-nofication text-center my-4 px-3">
                  <img src="assets/images/no-notification.png" class="mx-auto my-3">
                  <h5 class="fs-18">{{"header.noNotification" | translate}}</h5>
                  <p class="fs-13 text-grey-custom fw-500">{{"header.noNotificationSubHeading" | translate}}</p>
                </div>
                <ul class="dropdown-menu-right p-0 mb-0">
                  <li *ngFor="let item of notifyList">
                    <div class="top-text-block d-flex" [ngClass]="{'unread':!item.is_read}">
                      <div class="wrap w-100 cursor-pointer pe-3" (click)="readNotify(item)">
                        <div class="top-text-heading fs-14">{{ getCurrentLanguageName(item) }}</div>
                        <div class="top-text-light">{{item?.created_at | date }}</div>
                      </div>
                      <div class="cursor-pointer" title="{{'common.DeleteNotification' | translate}}" [ngClass]="{'text-grey':item.is_read}"
                        (click)="deleteNotify(item.id)"><i class="far fa-trash-alt"></i></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </ng-container>

          <!-- <div class="d-none"> -->
          <ng-container>
            <div class="d-xl-block d-none ">
              <app-language-dropdown (languageCodeEvent)="getLanguageCode($event)"></app-language-dropdown>
            </div>
          </ng-container>
          <!-- </div> -->

          <ng-container *ngIf="!userLoggedIn">
            <button id="login-link" type="button" class="btn btn-primary rounded-pill px-3 py-2 fw-600 ms-2" (click)="renderLoginModal()">
              {{"header.login" | translate}}
            </button>
          </ng-container>

          <ng-container *ngIf="userLoggedIn">
            <div class="dropdown profile-dropdown ms-2">
              <!-- <div routerLink="/post-auth/my-profile/leaderboard" class="leader-point">{{total_point ? total_point : 0}} pts</div> -->
              <button
                class="btn btn-outline-primary rounded-pill px-3 py-2 ms-auto fw-600 dropdown-toggle dropdown-toggle-custom"
                type="button" id="profileName" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img [src]="profileImage" *ngIf="profileImage" class="admin-img img-fluid rounded-pill me-2" />
                <span>{{ (profileName ? profileName : '') | limitTo: 6 }}</span>
              </button>
              <div class="dropdown-menu shadow-lg-custom" aria-labelledby="profileName">
                <a class="dropdown-item text-decoration-none fs-14" routerLink="/post-auth/my-profile">{{"header.myProfile" | translate}}</a>
                <a class="dropdown-item text-decoration-none fs-14" routerLink="/post-auth/my-activity">{{"header.myActivities" | translate}}</a>
                <!-- <a class="dropdown-item text-decoration-none fs-14" href="#">{{"header.setting" | translate}}</a> -->
                <a class="dropdown-item text-decoration-none fs-14" (click)="signout()">{{"header.logout" | translate}}</a>
              </div>
            </div>
          </ng-container>

       
          <ng-container *ngIf="userLoggedIn">
            <div class="dropdown ms-2 add-post">
              <button (click)="actionTour()"
                class="btn btn-primary rounded-pill p-0 minw-auto ms-auto fw-600 dropdown-toggle dropdown-toggle-custom no-dropdown-before border-0 p-2"
                type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                  style="enable-background: new 0 0 512 512" xml:space="preserve" width="13" class="fill-white">
                  <g>
                    <g>
                      <path d="M492,236H276V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v216H20c-11.046,0-20,8.954-20,20s8.954,20,20,20h216
			v216c0,11.046,8.954,20,20,20s20-8.954,20-20V276h216c11.046,0,20-8.954,20-20C512,244.954,503.046,236,492,236z" />
                    </g>
                  </g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg><span class="ms-1 position-relative">{{"header.add" | translate}}</span>
              </button>
              <div class="dropdown-menu shadow-lg-custom tour-open" [ngClass]="{ 'tour-menu' : showActionTour }" aria-labelledby="dropdownMenuButton">
                <a id="createCamp" class="dropdown-item text-decoration-none fs-14" routerLink="/post-auth/my-activity/add-campaign">{{"header.createCampaign" | translate}}</a>
                <a id="createProject" class="dropdown-item text-decoration-none fs-14" routerLink="/post-auth/manage-project/add">{{"header.createProject" | translate}}</a>
                <a id="createArticle" class="dropdown-item text-decoration-none fs-14"
                  routerLink="/post-auth/manage-article/add-article">{{"header.createArticle" | translate}}</a>
                <a id="createCommunity" class="dropdown-item text-decoration-none fs-14" routerLink="/pre-auth/community/add-community">{{"header.createCommunity" | translate}}</a>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </nav>
  </div>
</header>
<div id="tourElement">
<ngx-guided-tour
  skipText="{{'userGuide.skip' | translate}}"
  nextText="{{'userGuide.next' | translate}}"
  backText="{{'userGuide.back' | translate}}"
  doneText="{{'userGuide.complete' | translate}}"
></ngx-guided-tour>
</div>

<!-- <div *ngIf="userLoggedIn" routerLink="/post-auth/my-profile/leaderboard" class="point-pop">
  <span>{{total_point ? total_point : 0}} Points</span>
</div> -->

<div class="space-87"></div>