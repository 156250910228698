export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyC8J4Y4Pn3H9VuEeXauug3Ls1_qrzbmUnU",
    authDomain: "youth-action-platform-7522c.firebaseapp.com",
    projectId: "youth-action-platform-7522c",
    storageBucket: "youth-action-platform-7522c.appspot.com",
    messagingSenderId: "99612108709",
    appId: "1:99612108709:web:1f0f2624c2768a91182a4c"
  },
  // apiBaseUrl: 'https://api-yap.signitydemo.in/api/',
  apiBaseUrl: 'https://yapgateway.signitydemo.in/api/',

  client_id:
    '509814540329-viskjrugpjkls454k3906f04aj0tl7o1.apps.googleusercontent.com',
  imageUrl: 'https://d3fi8ele23t57x.cloudfront.net/',

  sharingUrl: 'https://yapgateway.signitydemo.in/api/agg/share'
};
