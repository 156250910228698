<div (mouseleave)="showDropDown = false" class="position-relative w-100">
  <button type="button" class="drop-toggle btn flat" (click)="showDropDown = !showDropDown">
    <span *ngIf="checkedList.length <= 0">{{placeholder}}</span>
    <span *ngIf="checkedList.length > 0"><span class="inner-item" *ngFor="let item of checkedList">
        <span>{{ item }}</span></span></span>
    <img src="assets/images/sort-down.svg" alt="icon" class="multidrop-icon" />
  </button>
  <div class="drop-show" *ngIf="showDropDown">
    <input class="search-dropdown__dropdown__search" type="text" placeholder="{{'common.search' | translate}}" [(ngModel)]="keyword"
      (keyup)="search(keyword)" #input />
    <label>
      <input type="checkbox" [(ngModel)]="allSelected" (change)="selectAllToggle()" />
      <span>{{'common.Selectall' | translate}}</span>
    </label>
    <div class="wrap">
    <ng-container *ngIf="asyncList">
      <label *ngFor="let a of asyncList | async">
        <input type="checkbox" [(ngModel)]="a.checked" (change)="getSelectedValue(a.checked, a)" />
        <span>{{  getTranslatedName(a) }}</span>
      </label>
    </ng-container>

    <ng-container *ngIf="list">
      <label *ngFor="let a of list">
        <input type="checkbox" [(ngModel)]="a.checked" (change)="getSelectedValue(a.checked, a)" />
        <span>{{ getTranslatedName(a) }}</span>
      </label>
    </ng-container>
  
  </div>
</div>
</div>
