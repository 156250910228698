<div class="px-2">
  <div class="modal-header justify-content-center border-0 pb-0" >
    <div class="form-title" id="staticBackdropLabel">
      <h4 class="text-center mt-3 mb-1">{{'login.login' | translate}}</h4>
    </div>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
  </div>
  <div class="px-md-4 px-3 pb-5">
    <form [formGroup]="loginForm" (ngSubmit)="submitForm()" novalidate>
      <div class="form-group mb-2 w-100">
        <label for="email" class="form-label mb-2 text-grey-2 w-100 fw-600 fs-14">{{'login.email' | translate}} <span
            class="text-danger">*</span> </label>
        <div class="group">
          <input  autocomplete="email" type="text" class="form-control" placeholder="{{'login.emailPlaceholder' | translate}}" aria-label="Username"
            aria-describedby="basic-addon1" formControlName="email"
            [ngClass]="{ 'outline-red': f['email'].invalid && f['email'].touched }" />
        </div>
        <app-validation-error [control]="f['email']" [controlName]="('login.email' | translate)" class="fs-14">
        </app-validation-error>
      </div>
      <div class="form-group mb-2 w-100">
        <label for="email" class="form-label mb-2 text-grey-2 w-100 fw-600 fs-14">{{'login.password' | translate}}
          <span class="text-danger">*</span></label>
        <div class="group position-relative">
          <input autocomplete="current-password" [type]="showPassword ? 'text' : 'password'" class="form-control" placeholder="{{'login.passwordPlaceholder' | translate}}"
            aria-label="password" aria-describedby="basic-addon1" formControlName="password"
            [ngClass]="{ 'outline-red': f['password'].invalid && f['password'].touched }" />
          <img [src]="showPassword ? 'assets/images/password-hide.png' : 'assets/images/password-view.png'"
            class="input-icon link" (click)="passwordToggle()" style="left: auto;right: 15px;top: 12px;">
        </div>
        <app-validation-error class="fs-14" [control]="f['password']" [controlName]="('login.password' | translate)">
        </app-validation-error>
      </div>

      <div class="row justify-content-end mx-0">
        <div class="form-check mb-0 w-auto">
          <label class="form-check-label">
            <a (click)="renderModal('FORGOT_PASSWORD')" class="fw-500 fs-14">{{'login.forgotPassword' | translate}}</a>
          </label>
        </div>
      </div>
      <button type="submit" class="btn btn-primary rounded-pill mx-auto d-block mt-4 px-5 w-100"> {{'login.login' | translate}} </button>
      <div class="or my-4 position-relative">
        <hr class="opacity-1" /> <span>{{'login.or' | translate}}</span>
      </div>
      <div class="google-btn position-relative">
        <button class="btn rounded-pill mx-auto d-block mt-4 px-3 w-100 g-btn"><svg viewBox="0 0 1152 1152" focusable="false" aria-hidden="true" role="img" class="spectrum-Icon spectrum-Icon--sizeM" data-social-button-type="icon" data-social-white-background="true"><path d="M1055.994 594.42a559.973 559.973 0 0 0-8.86-99.684h-458.99V683.25h262.28c-11.298 60.918-45.633 112.532-97.248 147.089v122.279h157.501c92.152-84.842 145.317-209.78 145.317-358.198z" fill="#4285f4"></path><path d="M588.144 1070.688c131.583 0 241.9-43.64 322.533-118.07l-157.5-122.28c-43.64 29.241-99.463 46.52-165.033 46.52-126.931 0-234.368-85.728-272.691-200.919H152.636v126.267c80.19 159.273 245 268.482 435.508 268.482z" fill="#34a853"></path><path d="M315.453 675.94a288.113 288.113 0 0 1 0-185.191V364.482H152.636a487.96 487.96 0 0 0 0 437.724z" fill="#fbbc05"></path><path d="M588.144 289.83c71.551 0 135.792 24.589 186.298 72.88l139.78-139.779C829.821 144.291 719.504 96 588.143 96c-190.507 0-355.318 109.21-435.508 268.482L315.453 490.75c38.323-115.19 145.76-200.919 272.691-200.919z" fill="#ea4335"></path></svg><span class="ms-2">{{'common.ContinuewithGoogle' | translate}}</span></button>
        <asl-google-signin-button #googlebutton type="standard" size="large" shape="circle" theme="outline"
        text="continue_with"></asl-google-signin-button>
      </div>

      <p class="text-center account-div fs-14 mb-0 mt-3 text-grey-custom">
        {{'login.donotHaveAnAccount' | translate}}
        <a (click)="renderModal('SIGNUP')" class="fw-600">{{'login.signup' | translate}}</a>
      </p>
    </form>
  </div>
</div>