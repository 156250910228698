import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from 'src/environment/environment';
import { ProfileSchema, objectType } from '../../interfaces';
import { HeaderService, MainService } from '../../services';
import { TokenStorageService } from 'src/app/core/services';
import { Router, RouterModule } from '@angular/router';
import { LimitToPipe } from '../../pipes';
import { CountryFlagModule } from '../../country-flag.module';
import { getFirstName } from '../../functions/function';

@Component({

  selector: 'app-activity-card-ui',
  standalone: true,
  imports: [CommonModule, RouterModule, LimitToPipe, CountryFlagModule],
  templateUrl: './activity-card-ui.component.html',
  styleUrls: ['./activity-card-ui.component.scss'],
})
export class ActivityCardUiComponent implements OnInit {
  @Input() selfUser: boolean = false;
  @Input() data!: any;
  @Input() countryName!: string;
  @Input() countryId!: string;
  @Output() showDetail$: EventEmitter<{ id: string }> = new EventEmitter<{ id: string; }>();
  @Output() postLikeDislike$: EventEmitter<{ id: string; body: any }> = new EventEmitter<{ id: string; body: any }>();
  public readonly imageUrl: string = environment.imageUrl;
  public ProfileData!: ProfileSchema;

  constructor(public mainService: MainService, private tokenStorageService: TokenStorageService, private headerService: HeaderService, private router: Router) { }

  ngOnInit(): void {
    if (this.selfUser) {
      this.ProfileData = this.mainService.getUserDetail.detail;
      if (this.ProfileData) { this.ProfileData.bgColor = this.mainService.getRandomColor(); }
    } else {
      this.ProfileData = this.data && this.data.user ? this.data.user : '';
      if (this.ProfileData) { this.ProfileData.bgColor = this.mainService.getRandomColor(); }
    }
  }

  public showDetail(_id: string) {
    this.showDetail$.emit({ id: _id });
    // if (this.isLoggedIn) { this.showDetail$.emit({ id: _id }); }
    // else { this.openLoginModal(); }
  }

  public postLikeDislike(data: objectType, isLiked: boolean, event: any) {
    event.stopPropagation();
    if (this.isLoggedIn) {
      const isLike = (isLiked && data['is_liked'] === true) || (isLiked === false && data['is_liked'] === false) ? null : isLiked;
      const _body: any = { is_liked: isLike === null ? null : isLike ? 'true' : 'false' };
      this.postLikeDislike$.emit({ id: data['id'], body: _body });
    } else { this.openLoginModal(); }
  }

  public showUserDetail(event: any) {
    event.stopPropagation();
    const _ = this;
    if (_.isLoggedIn) {
      this.router.navigate(_.selfUser ? ['/post-auth/my-profile'] : ['/post-auth/find-people/detail', _.ProfileData.id, _.ProfileData.profile.id]);
    } else { _.openLoginModal(); }
  }

  private get isLoggedIn(): boolean {
    if (this.tokenStorageService.getTokenSession && this.mainService.getUserDetail) { return true; } else { return false; }
  }

  private openLoginModal() { return (this.headerService._setRenderModal = 'LOGIN'); }

  public extractTextFromHTML(html: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

  getFirstName(name: string): string {
    return getFirstName(name)
   }
}
