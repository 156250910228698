<footer class="bg-black-2 py-md-5 py-4">
  <div class="container">
    <!-- <img
      src="assets/images/footer/footer-logo.svg"
      alt=""
      class="img-fluid mb-4 d-md-block d-none"
    /> -->
    <div class="row justify-content-between">
      <div class="col-md-5">
        <p class="text-white">{{'footer.pirOffice' | translate}}</p>
        <div class="address" *ngIf="footerData">
          <ul>
            <li class="text-grey address__full-address">{{footerData.address ? footerData.address : ''}}
            </li>
            <li class="text-grey address__phone">
              <a [href]="'tel:'+footerData.phone_number ? footerData.phone_number : ''">{{footerData.phone_number ?
                footerData.phone_number : ''}}</a>
            </li>
            <li class="text-grey address__email">
              <a [href]="'mailto:'+footerData.email ? footerData.email : ''">{{footerData.email ? footerData.email :
                ''}}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-4 footer-links ps-xl-5 mt-md-0 mt-4"
        *ngIf="footerData && (footerData.privacy_policy || footerData.about_us || footerData.terms_and_condition || footerData.support)">
        <p class="text-white fw-700 h5 mb-3 ">{{'footer.pirOffice' | translate}}</p>
        <ul>
          <li class="mb-1" *ngIf="footerData.privacy_policy">
            <a routerLink="/privacy-policy" class="text-grey">{{'footer.privacPolicy' | translate}}</a>
          </li>
          <li class="mb-1" *ngIf="footerData.about_us">
            <a routerLink="/about-us" class="text-grey">{{'footer.aboutUs' | translate}}</a>
          </li>
          <li class="mb-1" *ngIf="footerData.terms_and_condition">
            <a routerLink="/terms-conditions" class="text-grey">{{'footer.termsAndConditions' | translate}}</a>
          </li>
          <li class="mb-1" *ngIf="footerData.support">
            <a routerLink="/contact-support" class="text-grey">{{'footer.contactSupport' | translate}}</a>
          </li>
          <li class="mb-1" >
            <a routerLink="/community-guidelines" class="text-grey">{{'footer.communityGuidelines' | translate}}s</a>
          </li>
          <li *ngIf="footerData.user_guide" class="mb-1">
            <a (click)="guideTool()" class="text-grey">{{'footer.showGuideTool' | translate}}</a>
          </li>
          <li class="mb-1" >
            <a  routerLink="/copyright" class="text-grey">{{'footer.copyright' | translate}} </a>
          </li>
        </ul>
      </div>
      <div class="col-md-3 mt-lg-0 mt-4">
        <p class="text-grey">{{'footer.followUs' | translate}}:</p>
        <ul class="d-flex align-items-center" *ngIf="footerData && footerData.social_links">
          <li *ngFor="let link of footerData.social_links" (click)="goToLink(link.link)">
            <a><img [src]="imageUrl + link.image" class="img-fluid me-3 social-icon"/></a>
          </li>
          <!-- <li class="mx-4">
            <a href=""><img src="assets/images/footer/fb.svg" class="img-fluid" alt="Facebook" /></a>
          </li>
          <li>
            <a href=""><img src="assets/images/footer/twitter.svg" class="img-fluid" alt="Twitter" /></a>
          </li> -->
        </ul>
      </div>
    </div>
    <p class="text-grey fs-14 mb-0 mt-3 cursor-pointer" routerLink="/copyright">
      &copy;{{'footer.allRights' | translate}}
    </p>

    <!-- <div class="footer-cta text-center position-relative pt-3 mt-5">
      <button class="btn btn-white rounded-pill fw-700 bg-white text-primary px-4 d-none">Take action now</button>
        <img src="assets/images/footer/bottom-img.jpg" class="img-fluid" alt="">
      </div>   -->
  </div>
</footer>


<div class="scrolltotop" *ngIf="isShow" (click)="gotoTop()">
  <span><i class="fas fa-chevron-up"></i></span>
</div>