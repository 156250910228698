import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventBusService } from 'src/app/core/services';
import { Events } from 'src/app/core/enums';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class PageTitleService extends TitleStrategy {
  private subscription: Subscription = new Subscription();

  constructor(
    private readonly title: Title,
    private eventBusService: EventBusService,
    private translate: TranslateService
  ) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const titleKey = this.buildTitle(routerState);

    if (titleKey) {
      this.setPageTitle(titleKey);
    } else {
      this.title.setTitle('YAP');
    }

    this.subscription.add(
      this.eventBusService.on(Events.CHANGE_LANG, () => {
        if (titleKey) {
          this.setPageTitle(titleKey);
        }
      })
    );
  }

  private setPageTitle(key: string) {
    this.translate.get(`pageTitle.${key}`).subscribe((translatedTitle) => {
      this.title.setTitle(translatedTitle || 'YAP');
    });
  }
}
