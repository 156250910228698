import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-privacy-policy-mobile',
  templateUrl: './privacy-policy-mobile.component.html',
  styleUrls: ['./privacy-policy-mobile.component.scss']
})
export class PrivacyPolicyMobileComponent implements OnInit {
  constructor(private route: ActivatedRoute,  public translate: TranslateService) {}
  
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const languageCode = params['lang'] ? params['lang'] : 'en';
      if (languageCode) {
        localStorage.setItem('selectedLang',languageCode)
        this.translate.use(languageCode);
      }
    });
  }
}
