import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule } from '@ngx-translate/core';
import { I18nModule } from './i18n/i18n.module';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { CoreModule } from './core/core.module';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PageLoaderComponent } from './shared/components/page-loader/page-loader.component';
import {
  GoogleLoginProvider,
  GoogleSigninButtonModule,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { EditPostComponent } from './shared/Modals/edit-post/edit-post.component';
import { ReportModalComponent } from './shared/Modals/report-modal/report-modal.component';
import { FormsModule } from '@angular/forms';
import { GalleryModule } from '@ngx-gallery/core';
import { environment } from 'src/environment/environment';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { CookieConsentComponent } from './shared/components/cookie-consent/cookie-consent.component';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';
import { LightboxModule } from 'ngx-lightbox';
import { PageTitleService } from './shared/services/page-title.service';
import { TitleStrategy } from '@angular/router';
@NgModule({
  declarations: [AppComponent, EditPostComponent, ReportModalComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TranslateModule,
    CoreModule,
    FormsModule,
    BrowserAnimationsModule,
    I18nModule,
    HttpClientModule,
    LightboxModule,
    GalleryModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      timeOut:5000,
    }),
    NgbModule,
    PageLoaderComponent,
    SocialLoginModule,
    GoogleSigninButtonModule,
    ImageCropperModule,
    SlickCarouselModule,
    // CookieConsentComponent,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),
    provideFirestore(() => getFirestore()),
    GuidedTourModule,
  ],
  providers: [NgbActiveModal, GuidedTourService,
    { provide: TitleStrategy, useClass: PageTitleService },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '509814540329-viskjrugpjkls454k3906f04aj0tl7o1.apps.googleusercontent.com',
            ),
          },
        ],
        onError: (err) => { },
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
