import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Validator } from 'src/app/core/custom-validators/custom-validations';
import {
  CanContainOnlyAlphabets, CoreService, CountrySchema, CustomMessageService, EmailRegex, HasLowerCaseLetter,
  HasNumber, HasSpecialChar, HasUpperCaseLetter, HeaderService, InterestSchema, MobileRegex, UserAuthService, UserSignupForm,
} from 'src/app/shared';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { TokenStorageService } from 'src/app/core/services';
import { VerificationEmailComponent } from 'src/app/shared/Modals/verification-email/verification-email.component';
import { LoginComponent } from '../login/login.component';
import { DobVerificationComponent } from '../dob-verification/dob-verification.component';
import { AgeVerificationComponent } from '../age-verification/age-verification.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  public signupForm: UserSignupForm;
  private subscription: Subscription = new Subscription();
  public genderArray: Array<string> = ['Male', 'Female', 'Others'];
  public profileTypeArray: Array<string> = ['Public', 'Private'];
  public countryList: CountrySchema[] = [];
  public interestList: InterestSchema[] = [];
  public showPassword: boolean = false;
  public showConfirmPassword: boolean = false;
  public maxAgeDate: string = '';
  public languageList: any[]=[];

  constructor(private fb: FormBuilder, private activeModal: NgbActiveModal, private userAuthService: UserAuthService,
    private toasterService: CustomMessageService, private authService: SocialAuthService, public coreService: CoreService,
    private tokenStorageService: TokenStorageService, private modalService: NgbModal, private headerService: HeaderService, private translate: TranslateService) {

    const today = new Date();
    const fiveYearsBack = new Date(today.getFullYear() - 5, today.getMonth(), today.getDate());
    this.maxAgeDate = fiveYearsBack.toISOString().split('T')[0];

    this.signupForm = this.fb.group({
      name: ['', [
        Validators.required, Validators.maxLength(100), Validators.minLength(3), Validator.noWhiteSpaceValidator,
        Validator.patternValidator(CanContainOnlyAlphabets, { canContainOnlyAlphabets: true }),
      ]],
      city: ['', Validator.patternValidator(CanContainOnlyAlphabets, { canContainOnlyAlphabets: true })],
      country_id: ['', Validators.required],
      // gender: [''],
      profile_type: ['public', Validators.required],
      userInterests: [[], Validators.required],
      email: ['', [Validators.required, Validators.pattern(EmailRegex), Validators.maxLength(250), Validator.noWhiteSpaceValidator]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15),
      Validator.patternValidator(HasUpperCaseLetter, { hasCapitalCase: true }),
      Validator.patternValidator(HasLowerCaseLetter, { hasSmallCase: true }),
      Validator.patternValidator(HasSpecialChar, { hasSpecialCharacters: true }),
      Validator.patternValidator(HasNumber, { hasNumber: true })],
      ],
      confirm_password: ['', [Validators.required]],
      contact: ['', [Validators.pattern(MobileRegex)]],
      dob: ['',[Validators.required]],
      language_id: ['',[Validators.required]],
    }) as UserSignupForm;
  }

  ngOnInit(): void {
    this.ssoLogin();
    this.subscription.add(
      this.coreService.interests$.subscribe((ele) => { this.interestList = ele; this.clearCheckbox(); }),
    );
    this.getLanguages();
  }
  clearCheckbox() { this.interestList.forEach((ele: any) => { ele.checked = false; }); }

  public submitForm() {
    if (this.signupForm.valid && this.f['password'].value == this.f['confirm_password'].value) {
      let body = { ...this.signupForm.value };
      // if (!this.f['gender'].value) { delete body.gender; }
      if (!this.f['city'].value) { delete body.city; }
      if (!this.f['contact'].value) { delete body.contact; }
      
      this.subscription.add(
        this.userAuthService.httpUserSignup(body).subscribe({
          next: ({ data }) => {
              this.close();
              setTimeout(() => {
                const modalRef = this.modalService.open(VerificationEmailComponent, { backdrop: true, size: 'lg' });
                this.subscription.add(
                  modalRef.closed.subscribe((res) => {
                    if (res && res == 'LOG_IN') {
                      this.openLoginModal();
                    }
                  }),
                );
              }, 100);
          },
        }),
      );
    } else { this.signupForm.markAllAsTouched(); }
  }

  public openLoginModal() {
    const modalRef = this.modalService.open(LoginComponent, {
      backdrop: true,
      size: 'md', 
    });
  }

  public ssoLogin() {
    this.subscription.add(
      this.authService.authState.subscribe({
        next: (res) => {
          if (res && res.idToken) { this.googleLogin(res.idToken); }
        }, error: (err) => { },
      }),
    );
  }

  private googleLogin(token: string) {
    const body = { token: token };
    if (body) {
      this.subscription.add(
        this.userAuthService.ssoLogin(body).subscribe({
          next: ({ data }) => {
            if(!data?.consent ){
              this.close();
              this.ageVerificationFlow(data);
            }else{
              if (data && data.accessToken) {
                this.tokenStorageService.setTokenSession = data.accessToken;
                this.tokenStorageService.loginType = 'SSO';
              }
              this.toasterService.show(this.translate.instant('toastr.Successfullyloggedin'), 'success');
              this.close();
            }
          },
        }),
      );
    } else { this.toasterService.show(this.translate.instant('toastr.Unabletoauthentication'), 'error');}
  }

  public setGenderValue(value: string) { this.f['gender'].setValue(value || ''); }

  public setTypeProfileValue() {
    const value = this.f['profile_type'].value && this.f['profile_type'].value == 'public' ? 'private' : 'public';
    this.f['profile_type'].setValue(value || 'private');
  }

  public selectedInterestList(event: any[]) {
    this.f['userInterests'].setValue(event);
    this.f['userInterests'].markAsTouched();
  }

  public get f(): { [key: string]: AbstractControl } { return this.signupForm.controls; }

  public renderLoginModal() { this.activeModal.close('LOGIN'); }

  public close() { this.activeModal.close(); }
  passwordToggle() { this.showPassword = !this.showPassword; }
  confirmPasswordToggle() { this.showConfirmPassword = !this.showConfirmPassword; }

  ageVerificationFlow(data: any){
    if(!data?.dob){
      this.openDobVerificationModal(data);
    }else{
      this.openAgeVerificationModal(data);
    }
  }

  public openDobVerificationModal(data: any) {
    const modalRef = this.modalService.open(DobVerificationComponent, {
      backdrop: false,
      size: 'md', 
    });
    modalRef.componentInstance.patchData(data);
  }

  public openAgeVerificationModal(data: any) {
    const modalRef = this.modalService.open(AgeVerificationComponent, {
      backdrop: false,
      size: 'md', 
    });
    modalRef.componentInstance.patchDataAge(data?.user_id);
  }

  public getLanguages() {
    this.subscription.add(
      this.headerService.getLanguageList().subscribe({
        next: ({ data }) => {
          this.languageList = data;
          this.signupForm.patchValue({
            language_id: data[0]?.id
          })
        }
      }),
    );
  }

  onLanguageChange(event: any){
    this.f['language_id'].setValue(event?.id);
  }

  disableKeyboardInput(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
