<!-- Modal -->

<div class="modal-content border-0">
    <div class="modal-header justify-content-center">
      <h2 class="fs-4">{{'modal.leavingThisSite' | translate}}</h2>
      <button type="button" (click)="closemodal()" class="btn-close"></button>
    </div>
    <div class="modal-body border-0 py-3 px-4">
     <img src="assets/images/shuffle.svg" class="mx-auto d-block">
      <p class="guidelines fs-14 mb-0 mt-3 text-center">
        {{'common.Youarebeingredirected' | translate}}
      </p>
    </div>
    <div class="modal-footer border-0 justify-content-center mb-3">
      <button (click)="closemodal()" class="btn btn-outline-secondary me-2">{{'modal.stayHere' | translate}}</button>
      <button
        type="button"
        (click)="submit()"
        class="btn btn-primary"
      >
      {{'modal.continue' | translate}}
      </button>
    </div>
  </div>
  