import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_ROUTES } from '../routes';
import { HttpResponse, LoginSchema, UserSignupSchema } from '../interfaces';
import { Observable, from } from 'rxjs';
import { ContactSupportSchema } from 'src/app/feature/components/contact-support/contact-support.interface';
import { ProfileUser } from '../models/user-profile';
import { Firestore, doc, docData, setDoc } from '@angular/fire/firestore';
import { Auth, User, createUserWithEmailAndPassword, deleteUser, getAuth, signInWithEmailAndPassword } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class UserAuthService {
  constructor(private auth: Auth, private firestore: Firestore, private http: HttpClient) { }

  public httpLogin(data: LoginSchema): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(API_ROUTES.AUTH.LOGIN, data);
  }

  public httpUserSignup(data: UserSignupSchema): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<{ code: string }>>(API_ROUTES.AUTH.SIGNUP, data);
  }

  public ssoLogin(data: { token: string; }): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(API_ROUTES.AUTH.SSO_LOGIN, data);
  }

  public forgotPassword(payload: { email: string }): Observable<HttpResponse<{ code: string }>> {
    return this.http.post<HttpResponse<{ code: string }>>(API_ROUTES.AUTH.FORGOT_PWD, payload);
  }

  public resetPassword(payload: { password: string; }): Observable<HttpResponse<{ code: string }>> {
    return this.http.post<HttpResponse<{ code: string }>>(API_ROUTES.AUTH.RESET_PWD, payload);
  }

  public _validateUser(): Observable<HttpResponse<{ code: string }>> {
    return this.http.get<HttpResponse<{ code: string }>>(API_ROUTES.AUTH.ACTIVATE);
  }

  public httpPostSupportForm(data: ContactSupportSchema): Observable<HttpResponse<{ code: string }>> {
    return this.http.post<HttpResponse<{ code: string }>>(API_ROUTES.AUTH.CONTACTFORM, data);
  }
  signUp(email: string, password: string) {
    return from(createUserWithEmailAndPassword(this.auth, email, password));
  }

  deleteFirebaseUser() {
    const currentUser = getAuth().currentUser;
    if (currentUser) {
      return from(deleteUser(currentUser))
    } else {
      return null
    }
  }

  public addUser(user: ProfileUser): Observable<any> {
    localStorage.setItem('fireBaseUserId', user?.uid);
    const ref = doc(this.firestore, 'users', user?.uid);
    let userObj: any = user;
    let input =
    {
      firebaseId: user?.uid,
      userId: userObj?.id
    };
    return from(setDoc(ref, user));
  }
  login(username: string, password: string) {
    return from(signInWithEmailAndPassword(this.auth, username, password).catch(function (error) {
      return { msg: error.message, code: error.code };
    }));
  }


  public ageVerify(payload: { email: string, user_id: string }): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<{ code: string }>>(API_ROUTES.AUTH.AGE_VERIFY, payload);
  }

  public ageVerifyOtp(payload: { otp: string, user_id: string }): Observable<HttpResponse<{ code: string }>> {
    return this.http.post<HttpResponse<{ code: string }>>(API_ROUTES.AUTH.AGE_VERIFY_OTP, payload);
  }

  public dobVerify(payload: any): Observable<HttpResponse<any>> {
    return this.http.patch<HttpResponse<any>>(API_ROUTES.AUTH.DOB_VERIFY, payload);
  }
}
