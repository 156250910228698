import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { CustomMessageService, UserAuthService } from 'src/app/shared/services';
import { AgeVerificationComponent } from '../age-verification/age-verification.component';

@Component({
  selector: 'app-dob-verification',
  templateUrl: './dob-verification.component.html',
  styleUrls: ['./dob-verification.component.scss']
})
export class DobVerificationComponent implements OnDestroy {
  private subscription: Subscription = new Subscription();
  public dobForm!: any;
  public maxAgeDate: string = '';
  public user_id: string = '';

  constructor(private fb: FormBuilder, private activeModal: NgbActiveModal, private userAuthService: UserAuthService, private toasterService: CustomMessageService, private modalService: NgbModal) {
    const today = new Date();
    const fiveYearsBack = new Date(today.getFullYear() - 5, today.getMonth(), today.getDate());
    this.maxAgeDate = fiveYearsBack.toISOString().split('T')[0];

    
    this.dobForm = this.fb.group({
      dob: ['', [Validators.required]],
    });
   }

  public close() { this.activeModal.close('close'); }

  patchData(data?: any): void {
    this.user_id = data?.user_id
  }

  public submitDobForm() {
    if (this.dobForm.valid) {
      let payload = {
        dob: this.dobForm?.value?.dob,
        user_id: this.user_id
      }
      this.subscription.add(this.userAuthService.dobVerify(payload).subscribe({
        next: ({ data }) => {
          if(data){
            this.activeModal.close(data);
          }
        },
      }));
    } else { this.dobForm.markAllAsTouched(); }
  }

  public get f(): { [key: string]: AbstractControl } { return this.dobForm.controls; }

  disableKeyboardInput(event: KeyboardEvent): void {
    event.preventDefault();
  }

  ngOnDestroy(): void { this.subscription.unsubscribe(); }
}

