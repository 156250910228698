<ng-container>
    <div class="abt-page py-4">
        <div class="container">
            <div class="minh-100-custom">
                <div class="row">
                    <div class="col-12 col-md-12">
                        <div class="text-center mb-3">
                            <h2 class="fw-500 fs-1">{{'mobile.DeleteAccount' | translate}}</h2>
                        </div>
                        <div class="card shadow-lg-custom p-4 border-grey text-left rounded-4 my-3 border-0">
                            <div>
                                <div class="mb-2">
                                  <h6>{{'mobile.Step1' | translate}}</h6>
                                  {{'mobile.Logintotheapplication' | translate}} <br />
                                </div>
                        
                                <div class="mb-2">
                                  <h6>{{'mobile.Step2' | translate}}</h6>
                        
                                  {{'mobile.Navigatetothesidedrawermenu' | translate}}
                                </div>
                        
                                <div class="mb-2">
                                  <h6>{{'mobile.Step3' | translate}}</h6>
                        
                                  {{'mobile.ClickonMyProfile' | translate}}
                                </div>
                        
                                <div class="mb-2">
                                  <h6>{{'mobile.Step4' | translate}}</h6>
                        
                                  {{'mobile.Atthebottomofthepage' | translate}}
                                </div>
                                
                                <div class="mb-2">
                                  <h6>{{'mobile.Step5' | translate}}</h6>
                        
                                  {{'mobile.ClickonYes' | translate}}
                                </div>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>